import { useContext, useEffect, useRef } from 'react';
import { Alert, Button, Card, Col, Row, Typography } from 'antd';
import { SideinfoContext } from '../contexts/SideInfo';
import { Link } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { WindRoseControl } from '../maps/controls/rose-wind';
import Paragraph from 'antd/es/typography/Paragraph';
import { PoweroffOutlined } from '@ant-design/icons';
import { AuthContext } from "../contexts/Auth";
function Map({ installations, className, }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            installations.forEach(function (installation) {
                let stations = installation.stations;
                stations
                    .filter(station => station.position)
                    .forEach(station => {
                    if (station.position.lat > north) {
                        north = station.position.lat;
                    }
                    if (station.position.lat < south) {
                        south = station.position.lat;
                    }
                    if (station.position.lng > east) {
                        east = station.position.lng;
                    }
                    if (station.position.lng < west) {
                        west = station.position.lng;
                    }
                });
                installation.mapsLayers
                    .filter(layer => layer.type === 'polygon')
                    .forEach(layer => {
                    layer.points.forEach(({ lat, lng }) => {
                        if (lat > north) {
                            north = lat;
                        }
                        if (lat < south) {
                            south = lat;
                        }
                        if (lng > east) {
                            east = lng;
                        }
                        if (lng < west) {
                            west = lng;
                        }
                    });
                });
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 14,
                interactive: true,
            });
            map.current.on('load', () => {
                installations.forEach(function (installation) {
                    let stations = installation.stations;
                    const features = stations
                        .filter(station => station.position)
                        .map(station => ({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [station.position.lng, station.position.lat],
                        },
                        properties: {
                            title: `${station.position.lng} ${station.position.lat}`,
                        },
                    }));
                    map.current.addSource(`stations-` + installation.id, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features,
                        },
                    });
                    features.forEach((feature, i) => {
                        const el = document.createElement('div');
                        const station = stations[i];
                        el.className = `station station--${station.type} station--${station.type}-${station.last.value ?? '0'}`;
                        let html = '<b>' +
                            (station.last.value === null
                                ? station.last.value + station.last.units
                                : 'ND') +
                            '</b><br/>' +
                            station.name;
                        let popup = new mapboxgl.Popup({
                            offset: 25,
                            closeButton: false,
                        }).setHTML(html);
                        let marker = new mapboxgl.Marker(el)
                            .setLngLat(feature.geometry.coordinates)
                            .setPopup(popup)
                            .addTo(map.current);
                        el.addEventListener('mouseenter', () => {
                            marker.togglePopup();
                        });
                        el.addEventListener('mouseleave', () => {
                            marker.togglePopup();
                        });
                    });
                    const mapsLayers = installation.mapsLayers;
                    const layers = mapsLayers.map(({ points, name, color, type, opacity, geojson }) => ({
                        points,
                        name,
                        color,
                        type,
                        opacity,
                        geojson,
                    }));
                    layers
                        .filter(({ type }) => type === 'geojson')
                        .forEach(layer => {
                        const polygonId = `_${installation.id}_${layer.name}`;
                        map.current.addSource(polygonId, {
                            type: 'geojson',
                            data: layer.geojson,
                        });
                        map.current.addLayer({
                            id: `${installation.id}_${layer.name}`,
                            source: polygonId,
                            type: 'fill',
                            paint: {
                                'fill-color': layer.color,
                                'fill-opacity': layer.opacity ?? 0.25,
                            },
                        });
                    });
                    layers
                        .filter(({ type }) => type === 'geojson-circle')
                        .forEach(layer => {
                        const polygonId = `_${installation.id}_${layer.name}`;
                        map.current.addSource(polygonId, {
                            type: 'geojson',
                            data: layer.geojson,
                        });
                        map.current.addLayer({
                            id: `${installation.id}_${layer.name}`,
                            source: polygonId,
                            type: 'circle',
                            paint: {
                                'circle-radius': 5,
                                'circle-color': layer.color,
                                'circle-opacity': layer.opacity ?? 0.25,
                            },
                        });
                        layer.geojson.features.map((feature, i) => {
                            const el = document.createElement('div');
                            el.className = 'station geojson-circle';
                            let html = feature.properties.description ?? feature.properties.CODIGO;
                            let popup = new mapboxgl.Popup({
                                offset: 25,
                                closeButton: false,
                            }).setHTML(html);
                            let marker = new mapboxgl.Marker(el)
                                .setLngLat(feature.geometry.coordinates)
                                .setPopup(popup)
                                .addTo(map.current);
                            el.addEventListener('mouseenter', () => {
                                marker.togglePopup();
                            });
                            el.addEventListener('mouseleave', () => {
                                marker.togglePopup();
                            });
                        });
                    });
                    layers
                        .filter(({ type }) => type === 'polygon')
                        .forEach(layer => {
                        const polygonId = `_${installation.id}_${layer.name}`;
                        map.current.addSource(polygonId, {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [
                                        layer.points.map(({ lat, lng }) => [lng, lat]),
                                    ],
                                },
                            },
                        });
                        map.current.addLayer({
                            id: `${installation.id}_${layer.name}`,
                            source: polygonId,
                            type: 'fill',
                            paint: {
                                'fill-color': layer.color,
                                'fill-opacity': layer.opacity ?? 0.25,
                            },
                        });
                    });
                    layers
                        .filter(({ type }) => type === 'line')
                        .forEach((layer, i) => {
                        const lineId = `_${installation.id}_${i}`;
                        map.current.addSource(lineId, {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'LineString',
                                    coordinates: layer.points.map(({ lat, lng }) => [
                                        lng,
                                        lat,
                                    ]),
                                },
                            },
                        });
                        map.current.addLayer({
                            id: `${installation.id}_${i}`,
                            source: lineId,
                            type: 'line',
                            paint: {
                                'line-color': layer.color,
                                'line-width': 1,
                            },
                        });
                    });
                });
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([west - (west - east) / 2, south + (north - south) / 2]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    });
    return (<div ref={mapContainer} className={className} style={{
            height: 500,
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>);
}
function StationTypeFormatter(value) {
    switch (value) {
        case 'meteo':
            return 'Estación meteorológica';
        case 'bio':
            return 'Condiciones biomasa';
        case 'smoke':
            return 'Detección de humo';
        case 'ambiental':
            return 'Ambiental';
        case 'fwi':
            return 'Riesgo de incendio';
        case 'zeus':
            return 'Integración ZEUS API';
        default:
            return value;
    }
}
function LayersLegends({ installations }) {
    return (<>
            {installations
            .flatMap(({ mapsLayers }) => mapsLayers)
            .reduce((acc, layer) => {
            if (acc.find(({ name }) => name === layer.name) === undefined) {
                acc.push(layer);
            }
            return acc;
        }, [])
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(layer => (<div className="map-legend--item" key={layer.name}>
                        <div style={{ backgroundColor: layer.color }} className="map-legend--item-dot"></div>
                        <div className="map-legend--item-text">{layer.name}</div>
                    </div>))}
        </>);
}
function StationsLegends({ installations }) {
    return (<>
            {installations
            .flatMap(installation => installation.stations.reduce((acc, station) => {
            if (acc.find(s => station.type === s.type) === undefined) {
                acc.push(station);
            }
            return acc;
        }, []))
            .filter((station, i, stations) => stations.findIndex(s => s.type === station.type) === undefined)
            .slice()
            .sort((a, b) => StationTypeFormatter(a.type).localeCompare(StationTypeFormatter(b.type)))
            .map(station => (<div className="map-legend--item" key={station.id}>
                        <div className={`map-legend--item-dot station--${station.type}`}></div>
                        <div className="map-legend--item-text">
                            {StationTypeFormatter(station.type)}
                        </div>
                    </div>))}
        </>);
}
function MapLegends({ installations }) {
    return (<div className="map-legend">
            <LayersLegends installations={installations}/>
            <StationsLegends installations={installations}/>
        </div>);
}
export default function UserSideinfo() {
    const { installations, loading } = useContext(SideinfoContext);
    const { user, me } = useContext(AuthContext);
    return ((!loading && (<>
                <Row align="top">
                    <Typography.Title className="gTitle">
                        Panel de control de las instalaciones
                    </Typography.Title>
                </Row>
                <Row justify="space-around" align="top" id="sideinfo">
                    <Col span={24} className="gDashboardZone" id="sideinfoMap">
                        <Card>
                            {installations.length ? (<Col span={24} id="sideinfoMap">
                                    <Map installations={installations}/>
                                    <MapLegends installations={installations}/>
                                </Col>) : <Alert message={"Sección no disponible para esta instalación."} type={"warning"}/>}
                        </Card>
                    </Col>
                </Row>
                <Row justify="space-around" align="middle" className="gDashboardZones">
                    {installations.map(installation => (<Col className="gDashboardZone">
                            <Card title={installation.name} className={installation.class}>
                                <Paragraph>{installation.description}</Paragraph>
                                {installation.installation_data.start_capacity == 0 ? '' : <><Paragraph>
                                    <PoweroffOutlined /> Capacidad:{' '}
                                    {installation.installation_data.actual_capacity} de{' '}
                                    {installation.installation_data.start_capacity} minutos
                                </Paragraph><Paragraph>
                                    <PoweroffOutlined /> Capacidad:{' '}
                                    {((installation.installation_data.actual_capacity * 100) /
                    installation.installation_data.start_capacity).toFixed(2)}{' '}
                                    %
                                </Paragraph></>}


                                {user.roles.includes('sideinfo.activate') ? <Button type="primary">
                                    <Link to={`/sideinfo/${installation.id}`}>
                                        Ver instalación
                                    </Link>
                                </Button> : null}


                            </Card>
                        </Col>))}
                </Row>
            </>)) ||
        null);
}
