import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import useLoadCollection from '../hooks/useObjectsCollection';
import Collection from '../components/Collection';
import ItemDialog, { ItemAction } from '../components/ItemDialog';
import fields from '../fields/stations';
import deviceFields from '../fields/devices';
import { Col, Row } from 'antd';
export default function () {
    const { params: { id, child_type }, } = useRouteMatch();
    const { collection, querying, reloadCollection } = useLoadCollection(child_type ? `zone/${id}/${child_type}` : 'station');
    const history = useHistory();
    const [stationIdToDelete, setStationIdToDelete] = useState(null);
    const [stationIdToUpdate, setStationIdToUpdate] = useState(null);
    const [stationIdToAddDevice, setStationIdToAddDevice] = useState(null);
    function handleAddDeviceToStationDialogShow(id) {
        return () => {
            setStationIdToAddDevice(id);
        };
    }
    function handleAddDeviceToStationDialogHide() {
        setStationIdToAddDevice(null);
    }
    function handleDeleteDialogShow(id) {
        return () => {
            setStationIdToDelete(id);
        };
    }
    function handleDeleteDialogHide() {
        setStationIdToDelete(null);
    }
    function handleUpdateDialogShow(id) {
        return () => {
            setStationIdToUpdate(id);
        };
    }
    function handleUpdateDialogHide() {
        setStationIdToUpdate(null);
    }
    function goToDevices(id) {
        return () => {
            history.push(`/station/${id}/devices`);
        };
    }
    return (<Row justify="center" align="middle">
      <Col>
        <ItemDialog onCancel={handleAddDeviceToStationDialogHide} action={ItemAction.Create} title={item => `Añadir un dispositivo a la estación "${item?.name}"`} type="device" id={stationIdToAddDevice} onAccept={() => {
            history.push('/devices');
        }} fields={deviceFields} parentData={{ id: stationIdToAddDevice, key: 'station_id' }}/>
        <ItemDialog onCancel={handleUpdateDialogHide} action={ItemAction.Update} title={item => `Actualizar la estación "${item?.name}"`} fields={fields} id={stationIdToUpdate} type="station" onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                handleUpdateDialogHide();
            })();
        }} parentData={{ id: null, key: 'zone_id' }}/>
        <ItemDialog onCancel={handleDeleteDialogHide} action={ItemAction.Delete} type="station" title={(item) => `¿Eliminar la estación "${item?.name}"?`} fields={fields} id={stationIdToDelete} onAccept={reloadCollection}/>
        <Collection querying={querying} goToChildren={goToDevices} onItemDeleteRequest={handleDeleteDialogShow} onItemUpdateRequest={handleUpdateDialogShow} onCreateChildItemRequest={handleAddDeviceToStationDialogShow} collection={collection.map(item => ({
            ...item,
            position: JSON.stringify(item.position),
        }))} fields={fields}/>
      </Col>
    </Row>);
}
