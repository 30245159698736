import useAuth from '../hooks/useAuth';
import mapboxgl from 'mapbox-gl';
import * as toGeoJSON from '@tmcw/togeojson';
import { Alert, Button, Card, Col, Modal, Row, Select, Spin, Table, Typography, Upload, } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { WindRoseControl } from '../maps/controls/rose-wind';
import { Link, useHistory } from 'react-router-dom';
import { DownloadOutlined, FolderViewOutlined, Loading3QuartersOutlined, UploadOutlined, } from '@ant-design/icons';
import { MeasuresResume } from './MeasuresResume';
function SimulationsMap({ className, zone, simulations, onMapClick, projectId, }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    const [markers, setMarkers] = useState([]);
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 9,
                interactive: true,
            });
            map.current.on('load', () => {
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                if (zone.map.mode === 'kml') {
                    const kml = new DOMParser().parseFromString(zone.map.kml, 'text/xml');
                    const kmlGeoJSON = toGeoJSON.kml(kml);
                    kmlGeoJSON.features.forEach((feature) => {
                        if (feature.geometry.type == 'Polygon') {
                            feature.geometry.coordinates.map(function (points) {
                                points.forEach(function (point) {
                                    north = point[1] > north ? point[1] : north;
                                    south = point[1] < south ? point[1] : south;
                                    west = point[0] < west ? point[0] : west;
                                    east = point[0] > east ? point[0] : east;
                                });
                            });
                        }
                        else if (feature.geometry.type == 'MultiPolygon') {
                            feature.geometry.coordinates.map(function (polygon) {
                                polygon.forEach(function (points) {
                                    points.forEach(function (point) {
                                        north = point[1] > north ? point[1] : north;
                                        south = point[1] < south ? point[1] : south;
                                        west = point[0] < west ? point[0] : west;
                                        east = point[0] > east ? point[0] : east;
                                    });
                                });
                            });
                        }
                        else if (feature.geometry.type == 'LineString') {
                            feature.geometry.coordinates.map(function (point) {
                                north = point[1] > north ? point[1] : north;
                                south = point[1] < south ? point[1] : south;
                                west = point[0] < west ? point[0] : west;
                                east = point[0] > east ? point[0] : east;
                            });
                        }
                    });
                    map.current.addSource(`simulation-zones`, {
                        type: 'geojson',
                        data: kmlGeoJSON,
                    });
                }
                else {
                    ;
                    zone.map.geojson?.features.forEach((feature) => {
                        if (feature.geometry.type == 'Polygon') {
                            feature.geometry.coordinates.map(function (points) {
                                points.forEach(function (point) {
                                    north = point[1] > north ? point[1] : north;
                                    south = point[1] < south ? point[1] : south;
                                    west = point[0] < west ? point[0] : west;
                                    east = point[0] > east ? point[0] : east;
                                });
                            });
                        }
                        else if (feature.geometry.type == 'MultiPolygon') {
                            feature.geometry.coordinates.map(function (polygon) {
                                polygon.forEach(function (points) {
                                    points.forEach(function (point) {
                                        north = point[1] > north ? point[1] : north;
                                        south = point[1] < south ? point[1] : south;
                                        west = point[0] < west ? point[0] : west;
                                        east = point[0] > east ? point[0] : east;
                                    });
                                });
                            });
                        }
                        else if (feature.geometry.type == 'LineString') {
                            feature.geometry.coordinates.map(function (point) {
                                north = point[1] > north ? point[1] : north;
                                south = point[1] < south ? point[1] : south;
                                west = point[0] < west ? point[0] : west;
                                east = point[0] > east ? point[0] : east;
                            });
                        }
                    });
                    map.current.addSource(`simulation-zones`, {
                        type: 'geojson',
                        data: zone.map.geojson,
                    });
                }
                map.current.addLayer({
                    id: `simulation-zones-layer`,
                    source: `simulation-zones`,
                    type: 'fill',
                    paint: {
                        'fill-color': zone.map['fill-color'],
                        'fill-opacity': 0.25,
                    },
                });
                map.current.addLayer({
                    id: `simulation-outline-zone`,
                    type: 'line',
                    source: `simulation-zones`,
                    layout: {},
                    paint: {
                        'line-color': zone.map['line-color'],
                        'line-width': 3,
                    },
                });
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([
                    west - (west - east) / 2,
                    south + (north - south) / 2,
                ]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    }, [map, zone]);
    useEffect(() => {
        markers.forEach(marker => marker.remove());
        if (map.current) {
            map.current.on('click', (e) => {
                if (!Array.from(e.originalEvent.target.classList).includes('simulation')) {
                    onMapClick({ lat: e.lngLat.lat, lng: e.lngLat.lng });
                }
            });
            if (simulations && simulations.length > 0) {
                setMarkers(simulations.map((simulation) => {
                    const el = document.createElement('div');
                    let cX = 'failed';
                    if (simulation.code === '200') {
                        cX = 'finished';
                    }
                    if (simulation.code === '0' || simulation.code === '8') {
                        cX = 'pending';
                    }
                    el.className = `simulation simulation--${cX}`;
                    el.addEventListener('click', (e) => {
                        history.push(`/simulations/${simulation.id}`);
                    });
                    const marker = new mapboxgl.Marker(el)
                        .setLngLat([simulation.lng, simulation.lat])
                        .addTo(map.current);
                    return marker;
                }));
            }
        }
    }, [simulations]);
    return (<div id="map-container">
            <div id="map" ref={mapContainer} className={className} style={{
            height: '60vh',
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>
        </div>);
}
function LaunchFireSimulationDialog({ latLng, clearLatLng, reload, }) {
    const [hours, setHours] = useState();
    const { post, user, chosenProject } = useAuth();
    return (<Modal title="Lanzar una simulación" onOk={async () => {
            const response = await post('/api/fire-simulations/', {
                project_id: chosenProject,
                user_id: user.id.toString(),
                lat: latLng.lat.toString(),
                lng: latLng.lng.toString(),
                duration: `${hours ? hours : 5}h`,
            });
            if (response.ok) {
                reload();
                clearLatLng();
            }
        }} onCancel={clearLatLng} okText="Lanzar simulación" open={!!latLng}>
            <Typography.Paragraph>
                Seleccione la duración en horas de la simulación que desea lanzar
            </Typography.Paragraph>
            <Select onChange={value => {
            setHours(+value);
        }} defaultActiveFirstOption defaultValue={5}>
                setHours(5)
                {new Array(21).fill(null).map((_, i) => (<Select.Option value={i + 3} key={i}>
                        {i + 3}
                    </Select.Option>))}
            </Select>
        </Modal>);
}
function downloadBlob(filename, blob) {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    element.remove();
}
function SimulationsList({ simulations = [] }) {
    const { get, chosenProject } = useAuth();
    function handleGeoJSONDownload(id) {
        return () => {
            ;
            (async () => {
                const res = await get(`/api/fire-simulations/${chosenProject}/${id}/download`);
                if (res.ok) {
                    downloadBlob(`${id}.geojson`, await res.blob());
                }
            })();
        };
    }
    return simulations.length ? (<Table pagination={false} columns={[
            { title: 'Usuario', key: 'userName', dataIndex: 'userName' },
            { title: 'Mensaje', key: 'message', dataIndex: 'message' },
            { title: 'Latitud', key: 'lat', dataIndex: 'lat' },
            { title: 'Longitud', key: 'lng', dataIndex: 'lng' },
            { title: 'Fecha', key: 'createdAt', dataIndex: 'createdAt' },
            {
                title: '',
                key: 'id',
                dataIndex: 'id',
                render: (id, row) => {
                    return ['200'].includes(row.code) ? (<>
                                <Link key={id} to={`/simulations/${id}`}>
                                    <FolderViewOutlined />
                                    &nbsp;Ver
                                </Link>
                                &nbsp;
                                <Link key={id} onClick={handleGeoJSONDownload(id)} to="#">
                                    <DownloadOutlined />
                                    &nbsp;Descargar
                                </Link>
                            </>) : null;
                },
            },
        ]} dataSource={simulations} locale={{ emptyText: 'No hay simulaciones en este momento' }}/>) : (<Typography.Text>No hay simulaciones en este momento</Typography.Text>);
}
export default function FireSimulations() {
    const { get, chosenProject, tokenInfo } = useAuth();
    const [querying, setQuerying] = useState(true);
    const [simulations, setSimulations] = useState();
    const [zone, setZone] = useState();
    const [latLng, setLatLng] = useState();
    const [measures, setMeasures] = useState();
    const [refreshMapCount, setRefreshMapCount] = useState(0);
    async function getSimulationZones() {
        const response = await get('/api/fire-simulations/');
        const json = (await response.json());
        return {
            ...json,
            map: {
                ...json.map,
                geojson: json.map.geojson ? JSON.parse(json.map.geojson) : '',
            },
        };
    }
    async function getSimulations() {
        const response = await get(`/api/fire-simulations/${chosenProject}`);
        const simulations = (await response.json());
        return simulations.map(simulation => ({
            ...simulation,
            geojson: simulation.geojson ? JSON.parse(simulation.geojson) : {},
        }));
    }
    useEffect(() => {
        ;
        (async () => {
            if (tokenInfo) {
                setQuerying(true);
                const _zone = await getSimulationZones();
                setZone(_zone);
                if (_zone.canShowSimulations) {
                    setSimulations(await getSimulations());
                }
                setQuerying(false);
            }
        })();
    }, [tokenInfo]);
    function updateSimulationsData() {
        ;
        (async () => {
            if (zone?.canShowSimulations) {
                setSimulations(await getSimulations());
            }
        })();
    }
    useEffect(() => {
        if (tokenInfo && zone) {
            const interval = setInterval(updateSimulationsData, 10 * 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [tokenInfo, zone]);
    useEffect(() => {
        if (tokenInfo) {
            ;
            (async () => {
                const response = await get('/api/stats/dashboard');
                if (response.ok) {
                    const data = await response.json();
                    const _measures = data.last.reduce((acc, { value, type }) => {
                        const { name, units: unit } = data.types[type];
                        acc[type] = {
                            value,
                            name,
                            unit,
                            type,
                        };
                        return acc;
                    }, {});
                    setMeasures(_measures);
                }
            })();
        }
    }, [tokenInfo]);
    function onKmlLoad(kml) {
        if (kml.file.percent === 100) {
            ;
            (async () => {
                setZone(await getSimulationZones());
                setRefreshMapCount(count => count + 1);
            })();
        }
    }
    return (<>
            {querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
                            Se están cargando los datos de las zonas.{' '}
                            <Spin indicator={<Loading3QuartersOutlined />}/>
                        </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
                    <LaunchFireSimulationDialog clearLatLng={() => setLatLng(undefined)} latLng={latLng} reload={updateSimulationsData}/>
                    <Row align="top">
                        <Typography.Title className="gTitle">Simulaciones</Typography.Title>
                    </Row>
                    {measures && (<Row>
                            <Col span={24} style={{ padding: '1rem', paddingBottom: 0 }}>
                                <MeasuresResume measures={Object.values(measures)}/>
                            </Col>
                        </Row>)}
                    <Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                        <Col span={24} className="gDashboardZone" id="sideinfoMap">
                            <Card>
                                <SimulationsMap key={refreshMapCount} className="simulations-map" simulations={simulations} zone={zone} projectId={chosenProject} onMapClick={({ lat, lng }) => setLatLng({ lat, lng })}/>
                                {zone?.mode === 'interactive' ? (<div className="map-legend">
                                        <div className="map-legend--item">
                                            <div className="map-legend--item-dot simulation--failed"></div>
                                            <div className="map-legend--item-text">Error</div>
                                        </div>
                                        <div className="map-legend--item">
                                            <div className="map-legend--item-dot simulation--pending"></div>
                                            <div className="map-legend--item-text">En curso</div>
                                        </div>
                                        <div className="map-legend--item">
                                            <div className="map-legend--item-dot simulation--finished"></div>
                                            <div className="map-legend--item-text">Finalizada</div>
                                        </div>
                                    </div>) : ('')}
                            </Card>
                        </Col>
                    </Row>
                    {zone?.canShowSimulations ? (<Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                            <Col span={24} className="gDashboardZone" id="sideinfoMap">
                                <Card>
                                    <SimulationsList simulations={simulations}/>
                                </Card>
                            </Col>
                        </Row>) : ('')}
                    {zone?.canUploadKML ? (<Row justify="space-around" align="middle" className="gDashboardZones">
                            <Col span={24} className="gDashboardZone">
                                <Card>
                                    <Upload accept=".kml" onChange={onKmlLoad} maxCount={1} action="/api/fire-simulations/upload-kml" headers={{
                    Authorization: `Bearer ${tokenInfo?.token}`,
                }}>
                                        <Button icon={<UploadOutlined />}>Upload KML</Button>
                                    </Upload>
                                </Card>
                            </Col>
                        </Row>) : null}
                </>)}
        </>);
}
