import * as React from 'react';
import { Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, } from '@ant-design/icons';
export default function Collection({ collection, fields, goToChildren, onItemDeleteRequest, onItemUpdateRequest, onCreateChildItemRequest, querying, }) {
    const columns = [
        ...fields
            .filter(({ hideOnList }) => !hideOnList)
            .map(field => ({
            title: field.label,
            dataIndex: field.key,
            key: field.key,
            render: field.render,
            ellipsis: field.ellipsis,
        })),
        {
            title: '',
            key: 'actions',
            dataIndex: '',
            render: (text, { id }) => (<>
          {onCreateChildItemRequest ? (<Button onClick={onCreateChildItemRequest(id)} shape="circle" icon={<PlusOutlined />}/>) : null}
          {goToChildren ? (<Button shape="circle" onClick={goToChildren(id)} icon={<EyeOutlined />}/>) : null}
          <Button onClick={onItemUpdateRequest(id)} shape="circle" icon={<EditOutlined />}/>
          <Button onClick={onItemDeleteRequest(id)} shape="circle" icon={<DeleteOutlined />} danger/>
        </>),
        },
    ];
    const dataSource = collection.map(item => ({
        key: item.id,
        ...item,
    }));
    return (<>
      <Table dataSource={dataSource} columns={columns} loading={querying && { delay: 100 }} pagination={{
            position: ['topCenter', 'bottomCenter'],
        }}/>
    </>);
}
