import { Button, Card, Col, List, Modal, Row, Select, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import { SideinfoContext, } from '../contexts/SideInfo';
import mapboxgl from 'mapbox-gl';
import { WindRoseControl } from '../maps/controls/rose-wind';
import { LinkOutlined, PoweroffOutlined, } from '@ant-design/icons';
import { AuthContext } from '../contexts/Auth';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/es/typography/Paragraph';
import parseISO from 'date-fns/parseISO';
import { TimeWorking } from '../components/TimeWorking';
function Map({ installation }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            let stations = installation.stations;
            stations
                .filter(station => station.position)
                .forEach(({ position: { lat, lng } }) => {
                if (lat > north) {
                    north = lat;
                }
                if (lat < south) {
                    south = lat;
                }
                if (lng > east) {
                    east = lng;
                }
                if (lng < west) {
                    west = lng;
                }
            });
            installation.mapsLayers
                .filter(layer => layer.type === 'polygon')
                .forEach(layer => {
                layer.points.forEach(({ lat, lng }) => {
                    if (lat > north) {
                        north = lat;
                    }
                    if (lat < south) {
                        south = lat;
                    }
                    if (lng > east) {
                        east = lng;
                    }
                    if (lng < west) {
                        west = lng;
                    }
                });
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 14,
                interactive: true,
            });
            map.current.on('load', () => {
                let stations = installation.stations;
                const features = stations
                    .filter(station => station.position)
                    .map(station => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [station.position.lng, station.position.lat],
                    },
                    properties: {
                        title: `${station.position.lng} ${station.position.lat}`,
                    },
                }));
                map.current.addSource(`stations-` + installation.id, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features,
                    },
                });
                features.forEach((feature, i) => {
                    const el = document.createElement('div');
                    const station = stations[i];
                    el.className = `station station--${station.type} station--${station.type}-${station.last.value ?? '0'}`;
                    let html = '<b>' +
                        (station.last.value === null
                            ? station.last.value + station.last.units
                            : 'ND') +
                        '</b><br/>' +
                        station.name;
                    let popup = new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                    }).setHTML(html);
                    let marker = new mapboxgl.Marker(el)
                        .setLngLat(feature.geometry.coordinates)
                        .setPopup(popup)
                        .addTo(map.current);
                    el.addEventListener('mouseenter', () => {
                        marker.togglePopup();
                    });
                    el.addEventListener('mouseleave', () => {
                        marker.togglePopup();
                    });
                });
                const mapsLayers = installation.mapsLayers;
                const layers = mapsLayers.map(({ points, name, color, type, opacity, geojson }) => ({
                    points,
                    name,
                    color,
                    type,
                    opacity,
                    geojson,
                }));
                layers
                    .filter(({ type }) => type === 'geojson')
                    .forEach(layer => {
                    const polygonId = `_${installation.id}_${layer.name}`;
                    map.current.addSource(polygonId, {
                        type: 'geojson',
                        data: layer.geojson,
                    });
                    map.current.addLayer({
                        id: `${installation.id}_${layer.name}`,
                        source: polygonId,
                        type: 'fill',
                        paint: {
                            'fill-color': layer.color,
                            'fill-opacity': layer.opacity ?? 0.25,
                        },
                    });
                });
                layers
                    .filter(({ type }) => type === 'geojson-circle')
                    .forEach(layer => {
                    const polygonId = `_${installation.id}_${layer.name}`;
                    map.current.addSource(polygonId, {
                        type: 'geojson',
                        data: layer.geojson,
                    });
                    map.current.addLayer({
                        id: `${installation.id}_${layer.name}`,
                        source: polygonId,
                        type: 'circle',
                        paint: {
                            'circle-radius': 5,
                            'circle-color': layer.color,
                            'circle-opacity': layer.opacity ?? 0.25,
                        },
                    });
                    layer.geojson.features.map((feature, i) => {
                        const el = document.createElement('div');
                        el.className = 'station geojson-circle';
                        let html = feature.properties.description ?? feature.properties.CODIGO;
                        let popup = new mapboxgl.Popup({
                            offset: 25,
                            closeButton: false,
                        }).setHTML(html);
                        let marker = new mapboxgl.Marker(el)
                            .setLngLat(feature.geometry.coordinates)
                            .setPopup(popup)
                            .addTo(map.current);
                        el.addEventListener('mouseenter', () => {
                            marker.togglePopup();
                        });
                        el.addEventListener('mouseleave', () => {
                            marker.togglePopup();
                        });
                    });
                });
                layers
                    .filter(({ type }) => type === 'polygon')
                    .forEach(layer => {
                    const polygonId = `_${installation.id}_${layer.name}`;
                    map.current.addSource(polygonId, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Polygon',
                                coordinates: [
                                    layer.points.map(({ lat, lng }) => [lng, lat]),
                                ],
                            },
                        },
                    });
                    map.current.addLayer({
                        id: `${installation.id}_${layer.name}`,
                        source: polygonId,
                        type: 'fill',
                        paint: {
                            'fill-color': layer.color,
                            'fill-opacity': layer.opacity ?? 0.25,
                        },
                    });
                });
                layers
                    .filter(({ type }) => type === 'line')
                    .forEach((layer, i) => {
                    const lineId = `_${installation.id}_${i}`;
                    map.current.addSource(lineId, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: layer.points.map(({ lat, lng }) => [lng, lat]),
                            },
                        },
                    });
                    map.current.addLayer({
                        id: `${installation.id}_${i}`,
                        source: lineId,
                        type: 'line',
                        paint: {
                            'line-color': layer.color,
                            'line-width': 1,
                        },
                    });
                });
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([west - (west - east) / 2, south + (north - south) / 2]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    });
    return (<div ref={mapContainer} style={{
            height: 500,
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>);
}
export default function UserSideinfoSector() {
    const { installation_id, sector_id } = useParams();
    const { installations, performAction, loading, reload, getSideinfo } = useContext(SideinfoContext);
    const installation = installations.find(({ id }) => id === installation_id);
    const sectors = installation?.sectors || [];
    const sector = sectors.find(({ id }) => id === sector_id) ?? null;
    const { user } = useContext(AuthContext);
    const scenarios = Object.entries(installation.scenarios || {});
    const [chosenScenario, setChosenScenario] = useState(scenarios[0] && scenarios[0][0]);
    function handleOpenConfirmationModal(action) {
        return () => {
            Modal.confirm({
                onOk() {
                    performAction(`${action.name}`);
                },
                okText: 'Si',
                cancelText: 'No',
                content: (<Typography.Paragraph>
                        ¿Seguro que desea activar "{action.title}"?
                    </Typography.Paragraph>),
            });
        };
    }
    function StationTypeFormatter(value) {
        switch (value) {
            case 'meteo':
                return 'Estación meteorológica';
            case 'bio':
                return 'Condiciones biomasa';
            case 'smoke':
                return 'Detección de humo';
            case 'ambiental':
                return 'Ambiental';
            case 'fwi':
                return 'Riesgo de incendio';
            case 'zeus':
                return 'Integración ZEUS API';
            default:
                return value;
        }
    }
    function handlePerformAction(action) {
        return () => {
            performAction(action);
        };
    }
    function handleScenarioChange(newScenario) {
        setChosenScenario(newScenario);
    }
    let accInstallations = [];
    return ((!loading && (<>
                <Row align="top">
                    <Typography.Title className="gTitle">{sector.name}</Typography.Title>
                </Row>

                <Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                    <Col span={16} className="gDashboardZone" id="sideinfoMap">
                        <Card>
                            <Col span={24} id="sideinfoMap">
                                <Map installation={installation}/>
                                <div className="map-legend">
                                    {installation.mapsLayers
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(layer => (<div className="map-legend--item">
                                                <div style={{ backgroundColor: layer.color }} className="map-legend--item-dot"></div>
                                                <div className="map-legend--item-text">
                                                    {layer.name}
                                                </div>
                                            </div>))}

                                    {installations
            .flatMap(installation => installation.stations.reduce((acc, station) => {
            if (acc.find(s => station.type === s.type) === undefined) {
                acc.push(station);
            }
            return acc;
        }, accInstallations))
            .reduce((acc, station) => {
            if (acc.find(s => station.type === s.type) === undefined) {
                acc.push(station);
            }
            return acc;
        }, [])
            .slice()
            .sort((a, b) => StationTypeFormatter(a.type).localeCompare(StationTypeFormatter(b.type)))
            .map(station => (<div className="map-legend--item">
                                                <div className={`map-legend--item-dot station--${station.type}`}></div>
                                                <div className="map-legend--item-text">
                                                    {StationTypeFormatter(station.type)}
                                                </div>
                                            </div>))}
                                </div>
                            </Col>
                        </Card>
                    </Col>
                    <Col span={8} className="gDashboardZone" id="sideinfoSectors">
                        <Row>
                            <Col span={24} style={{
            textAlign: 'center',
            marginBottom: '1rem',
        }}>
                                Punto de inicio del incendio
                                <Select defaultValue={chosenScenario} onChange={handleScenarioChange} style={{ margin: '0 0 0 5px' }}>
                                    {scenarios.map(([value, label]) => (<Select.Option value={value}>{label}</Select.Option>))}
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <List size="small" bordered={false}>
                                        {sector.actions
            .filter(action => action.className
            .split(' ')
            .includes(`custom-action-${chosenScenario}`))
            .map(action => (<List.Item key={action.name}>
                                                    <Button type="default" className={action.className} onClick={handleOpenConfirmationModal(action)}>
                                                        {action.title}
                                                    </Button>
                                                </List.Item>))}
                                    </List>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {(installation.actions || []).map(action => (<Col flex="auto" style={{ padding: '1rem' }}>
                                    <Button block type="default" onClick={handleOpenConfirmationModal(action)} className={action.className}>
                                        {action.title}
                                    </Button>
                                </Col>))}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <List itemLayout="vertical">
                                        {user?.roles.includes('sideinfoespecial')
            ? sector.links.map(link => (<List.Item key={link.link}>
                                                    <a href={link.link} target="_blank" className="custom-link">
                                                        <LinkOutlined />
                                                        {link.title}
                                                    </a>
                                                </List.Item>))
            : null}
                                    </List>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ padding: '1rem 0' }}>
                                <Button block type="default">
                                    <Link to={`/sideinfo/${installation_id}`}>
                                        &laquo; Volver a la instalación
                                    </Link>
                                </Button>
                            </Col>
                        </Row>
                        {installation.installation_data.start_capacity > 0 ? <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <Paragraph>
                                    <PoweroffOutlined /> Capacidad del depósito:{' '}
                                    {installation.installation_data.actual_capacity} de{' '}
                                    {installation.installation_data.start_capacity} minutos
                                </Paragraph>
                                <Paragraph>
                                    <PoweroffOutlined /> Capacidad del depósito:{' '}
                                    {((installation.installation_data.actual_capacity * 100) /
                installation.installation_data.start_capacity).toFixed(2)}
                                    %
                                </Paragraph>
                                <TimeWorking key={reload} lastActionTimestamp={parseISO(installation.installation_data
                .last_action_timestamp_capacity)}/>
                            </Col>
                        </Row>
                    </> : null}
                        <Row>
                            <Col span={24}>
                                {sectors
            .filter(({ id }) => id !== sector_id)
            .map(sector => (<Button key={sector.id} block>
                                            <Link to={`/sideinfo/${installation_id}/${sector.id}`}>
                                                Ver {sector.name}
                                            </Link>
                                        </Button>))}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>)) ||
        null);
}
