import { memo, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
function _DeviceName({ id }) {
    const { get } = useContext(AuthContext);
    const [name, setName] = useState('...');
    useEffect(() => {
        ;
        (async () => {
            if (id) {
                const response = await get(`/api/device/${id}`);
                const { name } = await response.json();
                setName(name);
            }
        })();
    }, []);
    return <>{name}</>;
}
const DeviceName = memo(_DeviceName);
export default [
    {
        key: 'name',
        label: 'Nombre',
    },
    {
        key: 'description',
        label: 'Descripción',
    },
    {
        key: 'serial',
        label: 'Nº de serie',
        required: true,
    },
    {
        key: 'hash',
        label: 'Hash',
    },
    {
        key: 'type',
        label: 'Tipo',
        type: 'staticOptions',
        options: [
            { value: 'air_humidity', label: 'Humedad del aire' },
            { value: 'air_temperature', label: 'Temperatura del aire' },
            { value: 'alarm_status', label: 'Estado de la alarma' },
            { value: 'atm_pressure', label: 'Presión atmosférica' },
            { value: 'bui', label: 'BUI' },
            { value: 'ch4', label: 'CH4' },
            { value: 'co', label: 'CO' },
            { value: 'dc', label: 'DC' },
            { value: 'dmc', label: 'DMC' },
            { value: 'ffmc', label: 'FFMC' },
            { value: 'foliar_moisture', label: 'Humedad foliar' },
            { value: 'fwi', label: 'FWI' },
            { value: 'humidity', label: 'Humedad' },
            { value: 'isi', label: 'ISI' },
            { value: 'luminance', label: 'Luminosidad' },
            { value: 'nh3', label: 'NH3' },
            { value: 'no2', label: 'NO2' },
            { value: 'o2', label: 'O2' },
            { value: 'o3', label: 'O3' },
            { value: 'pi_duff', label: 'PI Duff' },
            { value: 'pi_max_vpd4', label: 'PI Max VPD4' },
            { value: 'pi_mf', label: 'PI MF' },
            { value: 'pi_mf_100h', label: 'PI MF 100h' },
            { value: 'pi_mf_10h', label: 'PI MF 10h' },
            { value: 'pi_mf_1h', label: 'PI MF 1h' },
            { value: 'pi_mf_woody', label: 'PI MF Woody' },
            { value: 'pi_mm', label: 'PI MM' },
            { value: 'pi_mmeteo', label: 'PI Mmeteo' },
            { value: 'pi_msoil', label: 'PI Msoil' },
            { value: 'pi_vpd', label: 'PI VPD' },
            { value: 'pm10', label: 'PM10' },
            { value: 'pm2', label: 'PM2' },
            { value: 'rain_day', label: 'Dia de lluvia' },
            { value: 'rain_hour', label: 'Hora de lluvia' },
            { value: 'rain_month', label: 'Mes lluvioso' },
            { value: 'rain_year', label: 'Año lluvioso' },
            { value: 'relative_humidity', label: 'Humedad relativa' },
            { value: 'running_time', label: 'Tiempo en ejecución' },
            { value: 'soil_humidity', label: 'Humedad del suelo' },
            { value: 'soil_temperature', label: 'Temperatura del suelo' },
            { value: 'sun_radiation', label: 'Radiación solar' },
            { value: 'temperature', label: 'Temperatura' },
            { value: 'voc', label: 'VOC' },
            { value: 'wind_direction', label: 'Dirección del viento' },
            { value: 'wind_speed', label: 'Velocidad del viento' },
        ],
    },
    {
        key: 'units',
        label: 'Unidades',
    },
    {
        key: 'mirror_id',
        label: 'Dispositivo vinculado',
        type: 'options',
        optionsMapper: {
            url: '/api/device',
            mapper: (options) => (options || []).map(({ id: value, name: label }) => ({
                value,
                label,
            })),
        },
        render: (deviceId) => <DeviceName id={deviceId}/>,
    },
];
