import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
export default function useAuth() {
    const [user, setUser] = useState(null);
    const [projects, setProjects] = useState([]);
    const [tokenInfo, setTokenInfo] = useState(null);
    const [querying, setQuerying] = useState(false);
    const [chosenProject, setChosenProject] = useState();
    const history = useHistory();
    async function login(email, password) {
        const response = await fetch('/api/login', {
            body: JSON.stringify({ email, password }),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const { token, token_type } = await response.json();
            const newTokenInfo = {
                token,
                tokenType: token_type,
            };
            setTokenInfo(newTokenInfo);
            sessionStorage.setItem('tokenInfo', JSON.stringify(newTokenInfo));
        }
        return response;
    }
    async function getUser() {
        try {
            const response = await get('/api/user/me');
            if (response.ok) {
                const { project_id: projectId, alerts, ...rest } = await response.json();
                const user = {
                    ...rest,
                    projectId: projectId.toString(),
                    alerts,
                };
                setUser(user);
                return user;
            }
        }
        finally {
            setQuerying(false);
        }
    }
    async function logout() {
        try {
            await clearChosenProject();
        }
        finally {
            setUser(null);
            setTokenInfo(null);
            sessionStorage.removeItem('tokenInfo');
        }
    }
    async function clearChosenProject() {
        await post('/api/user/changeProject', { project_id: undefined });
        setChosenProject(undefined);
        sessionStorage.removeItem('chosenProjectId');
    }
    async function chooseProject(id) {
        await post('/api/user/changeProject', { project_id: id });
        await getUser();
        setChosenProject(id);
        sessionStorage.setItem('chosenProjectId', id);
    }
    async function getProjects(user) {
        const response = await get(`/api/user/${user.id}/projects`);
        return await response.json();
    }
    useEffect(() => {
        const tokenInfoJSON = sessionStorage.getItem('tokenInfo');
        if (tokenInfoJSON) {
            setTokenInfo(JSON.parse(tokenInfoJSON));
        }
        const chosenProjectId = sessionStorage.getItem('chosenProjectId') || undefined;
        setChosenProject(chosenProjectId);
    }, []);
    useEffect(() => {
        ;
        (async () => {
            if (tokenInfo) {
                const user = await getUser();
                setProjects(await getProjects(user));
            }
        })();
    }, [tokenInfo]);
    async function get(endpoint) {
        return await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenInfo?.tokenType} ${tokenInfo?.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }
    async function post(endpoint, data) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': `${tokenInfo?.tokenType} ${tokenInfo?.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }; // TODO: We should fix this in the future
        if (data) {
            options.body = JSON.stringify(data);
        }
        const response = await fetch(endpoint, options);
        if (response.status === 401) {
            history.push('/login');
        }
        return response;
    }
    return {
        login,
        logout,
        user,
        tokenInfo,
        get,
        post,
        querying,
        projects,
        me: getUser,
        chooseProject,
        clearChosenProject,
        chosenProject,
    };
}
