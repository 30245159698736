import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Alert, Button, Card, Col, DatePicker, Radio, Row, Spin, Table, Typography } from 'antd';
import { GlobalOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import mapboxgl from 'mapbox-gl';
import { useHistory } from 'react-router';
import { WindRoseControl } from '../maps/controls/rose-wind';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip, } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import esLocale from 'antd/lib/date-picker/locale/es_ES';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);
moment.locale('es');
export var StationType;
(function (StationType) {
    StationType["METEO"] = "meteo";
    StationType["BIO"] = "bio";
    StationType["AMBIENTAL"] = "ambiental";
    StationType["FWI"] = "fwi";
    StationType["SMOKE"] = "smoke";
    StationType["PI"] = "pi";
    StationType["ZEUS"] = "zeus";
})(StationType || (StationType = {}));
function useStations(type) {
    const { get } = useContext(AuthContext);
    const [fromDate, setFromDate] = useState(startOfWeek(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [stations, setStations] = useState([]);
    const [stationsInfo, setStationsInfo] = useState();
    const [graphs, setGraphs] = useState();
    async function getStations(startDate, endDate) {
        return await get(`/api/stats/station/type/${type}?from_date=${moment(startDate)
            .startOf('day')
            .toISOString()}&to_date=${moment(endDate).endOf('day').toISOString()}`);
    }
    async function loadStations(startDate, endDate) {
        const response = await getStations(startDate, endDate);
        if (response.ok) {
            const data = await response.json();
            const stations = data.stations.map((station) => ({
                ...station,
                last: station.last.map(measure => ({
                    ...measure,
                    name: measure.name ? measure.name : data.types[measure.type].name,
                    units: data.types[measure.type].units,
                })),
            }));
            setStations(stations);
            setStationsInfo(data.info);
            setGraphs(data.graphs);
        }
    }
    return {
        stations,
        stationsInfo,
        graphs,
        loadStations,
        setFromDate,
        setToDate,
        fromDate,
        toDate,
    };
}
function StationInfo({ info, last, aggregate }) {
    const columns = [
        {
            title: 'Variable',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actual',
            dataIndex: 'last',
            key: 'last',
            align: 'right',
        },
    ];
    if (aggregate.length > 0) {
        columns.push({
            title: 'Mín',
            dataIndex: 'minimum',
            key: 'minimum',
            align: 'right',
        }, {
            title: 'Máx',
            dataIndex: 'maximum',
            key: 'maximum',
            align: 'right',
        }, {
            title: 'Media',
            dataIndex: 'average',
            key: 'average',
            align: 'right',
        });
    }
    const dataSource = last.map(function (value) {
        let aggregateRow = aggregate.find((item) => item.type === value.type);
        return {
            name: value.name + (value.units ? ' (' + value.units + ')' : ''),
            last: value.value,
            minimum: aggregateRow?.minimum,
            maximum: aggregateRow?.maximum,
            average: aggregateRow?.average,
        };
    });
    const history = useHistory();
    return (<Card title={info.name} className="gUserStationsStation">
            <Typography.Paragraph className="gUserStationDescription">
                <GlobalOutlined /> {info.position?.lat} {info.position?.lng}
            </Typography.Paragraph>

            <Table dataSource={dataSource} columns={columns} pagination={false}/>

            <Button block type="primary" className="gUserStationButton" onClick={() => {
            history.push(`/stations/${info.id}`);
        }}>
                Ver detalles
            </Button>
        </Card>);
}
function Map({ stations }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            stations
                .filter(station => station.info.position)
                .forEach(station => {
                if (station.info.position.lat > north) {
                    north = station.info.position.lat;
                }
                if (station.info.position.lat < south) {
                    south = station.info.position.lat;
                }
                if (station.info.position.lng > east) {
                    east = station.info.position.lng;
                }
                if (station.info.position.lng < west) {
                    west = station.info.position.lng;
                }
            });
            stations
                .filter(({ mapsLayers }) => mapsLayers)
                .forEach(({ mapsLayers }) => {
                mapsLayers.forEach(({ points, geojson }) => {
                    points.forEach(({ lat, lng }) => {
                        if (lat > north) {
                            north = lat;
                        }
                        if (lat < south) {
                            south = lat;
                        }
                        if (lng > east) {
                            east = lng;
                        }
                        if (lng < west) {
                            west = lng;
                        }
                    });
                    geojson.features.forEach((feature) => {
                        feature.geometry.coordinates.forEach((list) => {
                            list.forEach((points) => {
                                points.forEach((point) => {
                                    north = point[1] > north ? point[1] : north;
                                    south = point[1] < south ? point[1] : south;
                                    west = point[0] < west ? point[0] : west;
                                    east = point[0] > east ? point[0] : east;
                                });
                            });
                        });
                    });
                });
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 13,
                interactive: true,
            });
            map.current.on('load', () => {
                const features = stations.map(station => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [
                            station.info.position.lng,
                            station.info.position.lat,
                        ],
                    },
                }));
                map.current.addSource(`stations`, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features,
                    },
                });
                features.forEach((feature, i) => {
                    const el = document.createElement('div');
                    el.className = `station station--${stations[i].info.type}`;
                    el.addEventListener('click', () => {
                        history.push(`/stations/${stations[i].info.id}`);
                    });
                    let html = '<b>' + stations[i].info.name + '</b><br/>';
                    stations[i].last.map(station => {
                        html +=
                            '&nbsp;-&nbsp;' +
                                station.name +
                                ':&nbsp;' +
                                (station.value ? station.value + station.units : 'ND') +
                                '<br/>';
                    });
                    html += '';
                    let popup = new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                    }).setHTML(html);
                    let marker = new mapboxgl.Marker(el)
                        .setLngLat(feature.geometry.coordinates)
                        .setPopup(popup)
                        .addTo(map.current);
                    el.addEventListener('mouseenter', () => {
                        marker.togglePopup();
                    });
                    el.addEventListener('mouseleave', () => {
                        marker.togglePopup();
                    });
                });
                stations
                    .filter(({ mapsLayers }) => mapsLayers)
                    .forEach(station => {
                    station.mapsLayers.forEach(layer => {
                        const polygonId = `_${station.info.id}_${layer.name}`;
                        map.current.addSource(polygonId, {
                            type: 'geojson',
                            data: layer.geojson,
                        });
                        map.current.addLayer({
                            id: `${station.info.id}_${layer.name}`,
                            source: polygonId,
                            type: 'fill',
                            paint: {
                                'fill-color': layer.color,
                                'fill-opacity': layer.opacity ?? 0.25,
                                'fill-outline-color': 'black',
                            },
                        });
                        map.current.addLayer({
                            'id': `${station.info.id}_${layer.name}_line`,
                            'type': 'line',
                            'source': polygonId,
                            'layout': {},
                            'paint': {
                                'line-color': '#000',
                                'line-width': 2,
                            },
                        });
                    });
                });
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([
                    west - (west - east) / 2,
                    south + (north - south) / 2,
                ]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    });
    return (<div ref={mapContainer} style={{
            height: 500,
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>);
}
function ChartsRow({ graphs }) {
    let options = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: false,
        tension: 0.5,
        options: {
            scales: {},
        },
        plugins: {},
    };
    return (<Row gutter={20} justify="space-around" style={{ margin: '10px' }}>
            {graphs.map((graph, i) => (<Col span={24} key={i}>
                    <Card className="deviceChart">
                        <SimpleDataSheetChart options={options} graph={graph}/>
                    </Card>
                </Col>))}
        </Row>);
}
export function SimpleDataSheetChart({ options, graph, }) {
    const { datasets, labels } = graph;
    const plugins = {};
    plugins.datalabels = {
        display: false,
    };
    options = { ...options, plugins };
    const maxGraphValue = datasets.reduce((max, dataset) => {
        return Math.max(max, dataset.type.maxGraphValue ?? -Infinity);
    }, -Infinity);
    const minGraphValue = datasets.reduce((min, dataset) => {
        return Math.min(min, dataset.type.minGraphValue ?? Infinity);
    }, Infinity);
    const otherGraphValue = datasets.reduce((min, dataset) => {
        return Math.max(min, dataset.type.otherGraphValue ?? -Infinity);
    }, -Infinity);
    if (minGraphValue !== Infinity && maxGraphValue !== -Infinity) {
        console.log(options);
        options.plugins = {
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        borderDash: [5, 5],
                        yMin: minGraphValue,
                        yMax: minGraphValue,
                        borderColor: 'rgb(32, 128, 128)',
                        borderWidth: 1,
                        label: {
                            content: 'Bajo',
                            enabled: true,
                            position: 'start',
                            backgroundColor: 'rgb(32, 128, 128)',
                        },
                    },
                    line2: {
                        type: 'line',
                        borderDash: [5, 5],
                        yMin: maxGraphValue,
                        yMax: maxGraphValue,
                        borderColor: 'rgb(256, 196, 0)',
                        borderWidth: 1,
                        label: {
                            content: 'Alto',
                            enabled: true,
                            position: 'start',
                            backgroundColor: 'rgb(256, 196, 0)',
                        },
                    },
                    line3: {
                        type: 'line',
                        borderDash: [5, 5],
                        yMin: otherGraphValue,
                        yMax: otherGraphValue,
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 1,
                        label: {
                            content: 'Muy alto',
                            enabled: true,
                            position: 'start',
                            backgroundColor: 'rgb(255, 99, 132)',
                        },
                    },
                },
            },
        };
    }
    !(options && options.plugins) ? undefined : options.plugins.datalabels = { display: false };
    return (<Chart type="line" options={options} data={{
            labels,
            datasets: datasets.map(({ title, type: { type, name, color }, data, chartType }) => ({
                type: 'line',
                label: title,
                data,
                borderColor: color,
                backgroundColor: color,
                pointBackgroundColor: color,
            })),
        }}/>);
}
function StationTypeFormatter(value) {
    switch (value) {
        case 'meteo':
            return 'Estación meteorológica';
        case 'bio':
            return 'Condiciones biomasa';
        case 'smoke':
            return 'Detección de humo';
        case 'ambiental':
            return 'Ambiental';
        case 'fwi':
            return 'Riesgo de incendio';
        case 'pi':
            return 'Riego prescrito';
        case 'zeus':
            return 'Monitorización hidráulica instalaciones SIDEINFO';
        default:
            return value;
    }
}
function Legends({ stations }) {
    let legends = {};
    stations.forEach(station => {
        ;
        (station.mapsLayers || []).forEach(({ mapsLegend }) => {
            ;
            (mapsLegend || []).forEach(({ title, color, className }) => {
                if (!legends[title]) {
                    legends[title] = {
                        color,
                        type: station.info.type,
                        className: className,
                    };
                }
            });
        });
    });
    return (<>
            {Object.entries(legends).map(([title, { color, type, className }], i) => (<div className={`map-legend--item map-legend--item-${type}-${className}`} key={i}>
                    <div className={`map-legend--item-dot station--${type}-${className}`} style={{ backgroundColor: color }}></div>
                    <div className="map-legend--item-text">{title}</div>
                </div>))}
        </>);
}
export default function UserStations({ type }) {
    const { stations, stationsInfo, graphs, loadStations, setFromDate, setToDate, fromDate, toDate, } = useStations(type);
    const [querying, setQuerying] = useState(true);
    const [chosenRange, setChosenRange] = useState('this-week');
    useEffect(() => {
        ;
        (async () => {
            await loadStations(fromDate, toDate);
        })();
    }, [fromDate, toDate]);
    useEffect(() => {
        ;
        (async () => {
            setQuerying(true);
            await loadStations(fromDate, toDate);
            setQuerying(false);
        })();
    }, []);
    function handleChangeRange(range) {
        setChosenRange(range);
        switch (range) {
            case 'today':
                setFromDate(startOfDay(new Date()));
                break;
            case 'this-week':
                setFromDate(startOfWeek(new Date()));
                break;
            case 'this-month':
                setFromDate(startOfMonth(new Date()));
                break;
            case 'this-year':
                setFromDate(startOfYear(new Date()));
                break;
        }
        setToDate(new Date());
    }
    return querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
                    Se están cargando los datos de las estaciones.
                    <Spin indicator={<Loading3QuartersOutlined />}/>
                </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
            <Row align="top">
                <Typography.Title className="gTitle">
                    {stationsInfo?.title}
                </Typography.Title>
            </Row>
            <Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                <Col span={24} className="gDashboardZone" id="sideinfoMap">
                    <Card>
                        {stations.length ? (<Map stations={stations}/>) : (<Alert message={'Sección no disponible para esta instalación.'} type={'warning'}/>)}

                        <div className="map-legend">
                            <Legends stations={stations}/>

                            {stations
            .reduce((acc, station) => {
            if (acc.find(s => station.info.type === s.info.type) ===
                undefined) {
                acc.push(station);
            }
            return acc;
        }, [])
            .map(station => (<div className={`map-legend--item map-legend--item-${station.info.type}`}>
                                        <div className={`map-legend--item-dot station--${station.info.type}`}></div>
                                        <div className="map-legend--item-text">
                                            {StationTypeFormatter(station.info.type)}
                                        </div>
                                    </div>))}
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row justify="space-around" align="middle" className="gDashboardZones">
                {stations.map((station) => (<Col key={station.info.id} className="gDashboardZone">
                        <StationInfo {...station}/>
                    </Col>))}
            </Row>

            {graphs && graphs.length > 0 ? (<Row className="gDashboardZones" justify="space-around" align="middle">
                    <Card id="gDeviceDateSelector">
                        <Radio.Group value={chosenRange} onChange={e => {
                handleChangeRange(e.target.value);
            }}>
                            <Radio.Button value="today">Hoy</Radio.Button>
                            <Radio.Button value="this-week">Esta semana</Radio.Button>
                            <Radio.Button value="this-month">Este mes</Radio.Button>
                            <Radio.Button value="this-year">Este año</Radio.Button>
                        </Radio.Group>

                        <DatePicker.RangePicker allowEmpty={[false, false]} allowClear={false} disabledDate={date => date.isAfter(moment(new Date()))} onChange={dates => {
                if (dates) {
                    setFromDate(dates[0].toDate());
                    setToDate(dates[1].toDate());
                    setChosenRange(null);
                }
            }} format="DD/MM/YYYY" bordered={false} locale={esLocale} value={[moment(fromDate), moment(toDate)]}/>
                    </Card>
                </Row>) : null}

            <ChartsRow graphs={graphs ?? []}/>

            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Alert message={stationsInfo?.lastData} type="info" showIcon/>
            </Row>
        </>);
}
