import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import useLoadCollection from '../hooks/useObjectsCollection';
import { Col, Row } from 'antd';
import Collection from '../components/Collection';
import fields from '../fields/devices';
import ItemDialog, { ItemAction } from '../components/ItemDialog';
export default function () {
    const { params: { id, child_type }, } = useRouteMatch();
    const { collection, querying, reloadCollection } = useLoadCollection(child_type ? `station/${id}/devices` : 'device');
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const [deviceIdToUpdate, setDeviceIdToUpdate] = useState(null);
    function handleDeleteDialogShow(id) {
        return () => {
            setDeviceIdToDelete(id);
        };
    }
    function handleDeleteDialogHide() {
        setDeviceIdToDelete(null);
    }
    function handleUpdateDialogShow(id) {
        return () => {
            setDeviceIdToUpdate(id);
        };
    }
    function handleUpdateDialogHide() {
        setDeviceIdToUpdate(null);
    }
    return (<Row justify="center" align="middle">
      <Col>
        <ItemDialog type="device" id={deviceIdToUpdate} fields={fields} action={ItemAction.Update} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                handleUpdateDialogHide();
            })();
        }} onCancel={handleUpdateDialogHide} title={() => `Actualizar el dispositivo "${collection.find(({ id }) => id === deviceIdToUpdate)?.name}"`} parentData={{
            key: 'station_id',
            id: null,
        }}/>
        <ItemDialog type="device" id={deviceIdToDelete} fields={fields} action={ItemAction.Delete} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                handleDeleteDialogHide();
            })();
        }} onCancel={handleDeleteDialogHide} title={item => `¿Borrar dispositivo "${item?.name}"?`}/>
        <Collection querying={querying} collection={collection} fields={fields} onItemDeleteRequest={handleDeleteDialogShow} onItemUpdateRequest={handleUpdateDialogShow}/>
      </Col>
    </Row>);
}
