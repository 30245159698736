import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useState, useContext } from 'react';
import { Layout, Menu } from 'antd';
import { FaBuilding, FaFolder, FaMapMarked, FaMobile, FaPlay, FaPowerOff, FaUser, } from 'react-icons/fa';
import ProjectsList from '../views/ProjectsList';
import ZonesList from '../views/ZonesList';
import StationsList from '../views/StationsList';
import Devices from '../views/Devices';
import DevicesActions from '../views/DevicesActions';
import Users from '../views/Users';
import { AuthContext } from '../contexts/Auth';
function Administrator() {
    const location = useLocation();
    const { logout } = useContext(AuthContext);
    const [current, setCurrent] = useState(location.pathname);
    function handleLogoutClick(ev) {
        ev.preventDefault();
        (async () => await logout())();
    }
    return (<Layout>
      <Layout.Sider breakpoint="lg">
        <Menu selectedKeys={[current]} onClick={({ key }) => setCurrent(key)} mode="inline" items={[
            {
                key: '/projects',
                icon: <FaFolder />,
                label: <Link to="/projects">Proyectos</Link>,
            },
            {
                key: '/zones',
                icon: <FaMapMarked />,
                label: <Link to="/zones">Zonas</Link>,
            },
            {
                key: '/stations',
                icon: <FaBuilding />,
                label: <Link to="/stations">Estaciones</Link>,
            },
            {
                key: '/devices',
                icon: <FaMobile />,
                label: <Link to="/devices">Dispositivos</Link>,
            },
            {
                key: '/actions',
                icon: <FaPlay />,
                label: <Link to="/actions">Acciones</Link>,
            },
            {
                key: '/users',
                icon: <FaUser />,
                label: <Link to="/users">Usuarios</Link>,
            },
            {
                key: '/logout',
                icon: <FaPowerOff />,
                label: <span onClick={handleLogoutClick}>Cerrar sesión</span>,
            },
        ]}/>
      </Layout.Sider>

      <Layout>
        <Layout.Header></Layout.Header>

        <Layout.Content>
          <Switch>
            <Route path="/projects" exact component={ProjectsList}/>
            <Route path="/projects/:id/:child_type" component={ZonesList}/>
            <Route path="/zones" exact component={ZonesList}/>
            <Route path="/zones/:id/:child_type" component={StationsList}/>
            <Route path="/stations" component={StationsList}/>
            <Route path="/station/:id/:child_type" component={Devices}/>
            <Route path="/devices" exact component={Devices}/>
            <Route path="/actions" component={DevicesActions}/>
            <Route path="/users" component={Users}/>
            <Route path="/" exact>
              <Redirect to="/projects"/>
            </Route>
          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>);
}
export default Administrator;
