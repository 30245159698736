import { Redirect, Route, Switch } from 'react-router';
import Dashboard from '../views/Dashboard';
import CustomerLayout from '../components/CustomerLayout';
import UserStations, { StationType } from '../views/UserStations';
import UserDevice from '../views/UserDevice';
import UserStation from '../views/UserStation';
import Zones from "../views/Zones";
import MapStations, { MapType } from "../views/MapType";
import FireSimulations from "../views/FireSimulations";
import FireSimulationDetails from "../views/FireSimulationDetails";
import UserSideinfo from "../views/UserSideinfo";
import UserSideinfoSector from "../views/UserSideinfoSector";
import UserSideinfoInstallation from "../views/UserSideinfoInstallation";
import UserAlerts from "../views/UserAlerts";
import SideinfoProvider from "../contexts/SideInfo";
export default function BasicCustomer({ roles }) {
    return (<CustomerLayout>
            <Switch>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/zones">
                    <Zones />
                </Route>
                <Route path={'/stations/' + StationType.METEO}>
                    <UserStations type={StationType.METEO} key={StationType.METEO}/>
                </Route>
                <Route path={'/stations/' + StationType.AMBIENTAL}>
                    <UserStations type={StationType.AMBIENTAL} key={StationType.AMBIENTAL}/>
                </Route>
                <Route path={'/stations/' + StationType.BIO}>
                    <UserStations type={StationType.BIO} key={StationType.BIO}/>
                </Route>
                <Route path={'/stations/' + StationType.SMOKE}>
                    <UserStations type={StationType.SMOKE} key={StationType.SMOKE}/>
                </Route>
                <Route path={'/stations/' + StationType.FWI}>
                    <UserStations type={StationType.FWI} key={StationType.FWI}/>
                </Route>
                <Route path={'/stations/' + StationType.PI}>
                    <UserStations type={StationType.PI} key={StationType.PI}/>
                </Route>
                <Route path={'/stations/' + StationType.ZEUS}>
                    <UserStations type={StationType.ZEUS} key={StationType.ZEUS}/>
                </Route>
                <Route path={'/stations/map/' + MapType.VOC_ALARM_STATUS}>
                    <MapStations type={MapType.VOC_ALARM_STATUS} key={MapType.VOC_ALARM_STATUS} binary/>
                </Route>
                <Route path={'/stations/map/' + MapType.TEMPERATURE}>
                    <MapStations type={MapType.TEMPERATURE} key={MapType.TEMPERATURE}/>
                </Route>
                <Route path={'/stations/map/' + MapType.HUMIDITY}>
                    <MapStations type={MapType.HUMIDITY} key={MapType.HUMIDITY}/>
                </Route>
                <Route path={'/stations/map/' + MapType.ATM_PRESSURE}>
                    <MapStations type={MapType.ATM_PRESSURE} key={MapType.ATM_PRESSURE}/>
                </Route>
                <Route path={`/stations/:id`}>
                    <UserStation />
                </Route>
                <Route path={`/devices/:id`}>
                    <UserDevice />
                </Route>
                <Route path="/simulations">
                    <Switch>
                        <Route path="/simulations/:id">
                            <FireSimulationDetails />
                        </Route>
                        <Route path="/simulations/">
                            <FireSimulations />
                        </Route>

                    </Switch>
                </Route>
                <Route path="/sideinfo">
                    <SideinfoProvider>
                        <Switch>
                            <Route path="/sideinfo/:installation_id/:sector_id">
                                <UserSideinfoSector />
                            </Route>
                            <Route path="/sideinfo/:installation_id">
                                <UserSideinfoInstallation />
                            </Route>
                            <Route path="/sideinfo">
                                <UserSideinfo />
                            </Route>


                        </Switch>
                    </SideinfoProvider>
                </Route>
                <Route path="/alerts">
                    <UserAlerts />
                </Route>
                <Route path="/" exact>
                    <Redirect to="/dashboard"/>
                </Route>
            </Switch>
        </CustomerLayout>);
}
