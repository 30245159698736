import { Image, Layout, Typography } from 'antd';
import React, { memo, useContext, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import BasicUserMenu from './BasicUserMenu';
function _Title(props) {
    return (<>
            <Image id="header_logo_sideinfo" key="logo" src={props.images?.header.menu} className="header-menu-sideinfo" preview={false}/>
            <Typography.Title id="header_title">
                {props.projectTitle}
            </Typography.Title>
            <Image id="header_logo_netsense" key="banner" src={props.images?.header.banner} className="header-menu-netsense" preview={false}/>
        </>);
}
const Title = memo(_Title);
export default function CustomerLayout({ children }) {
    const [collapsed, setCollapsed] = useState(false);
    const { user, me } = useContext(AuthContext);
    const images = user?.images;
    const project_title = user?.project_title;
    const [bellowBreakpoint, setBellowBreakpoint] = useState(false);
    function onCollapse(_collapsed) {
        setCollapsed(_collapsed);
    }
    function onBreakpoint(_bellowBreakpoint) {
        setBellowBreakpoint(_bellowBreakpoint);
    }
    return (<Layout style={{ minHeight: '100vh' }}>
            <Layout.Header>
                <>
                    {bellowBreakpoint ? (collapsed ? (<MenuUnfoldOutlined className="side-menu-trigger" onClick={() => onCollapse(false)} id="hamburger-menu-trigger"/>) : (<MenuFoldOutlined className="side-menu-trigger" onClick={() => onCollapse(true)} id="hamburger-menu-trigger"/>)) : null}
                </>
                <Title images={images} projectTitle={project_title}/>
            </Layout.Header>
            <Layout>
                <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse} breakpoint="md" onBreakpoint={onBreakpoint} trigger={null} collapsedWidth={0}>

                    <BasicUserMenu roles={user.roles} onItemClick={() => {
            bellowBreakpoint && setCollapsed(true);
        }}/>

                    {images.menu ? <Image src={images.menu} preview={false}/> : null}
                </Layout.Sider>
                <Layout.Content>
                    {children}
                    <Layout.Footer id="footer">
                        {images?.footer.map((image) => (<Image key={image} src={image} className="footer-logo" preview={false}/>))}
                    </Layout.Footer>
                </Layout.Content>
            </Layout>
        </Layout>);
}
