import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Modal, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import useSound from 'use-sound';
import notificationSound from '../../sounds/notification.mp3';
export default function useAlerts() {
    const [alertsCount, setAlertsCount] = useState();
    const [shouldShow, setShouldShow] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [inter, setInter] = useState();
    const { user, me } = useContext(AuthContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const [play] = useSound(notificationSound);
    function createNotification() {
        const notification = new Notification('¡Nuevas alertas!', {
            body: 'Tiene nuevas alertas pendientes de revisar',
            image: user.images.header.menu,
            icon: user.images.header.menu,
            requireInteraction: true,
        });
        notification.onclick = function () {
            window.focus();
            this.close();
        };
    }
    function notifyMe() {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            // alert('This browser does not support desktop notification')
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            createNotification();
        }
        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    createNotification();
                }
            });
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }
    function showModal() {
        if (pathname !== '/alerts') {
            notifyMe();
            Modal.confirm({
                okText: 'Si',
                cancelText: 'No',
                title: '¡Nuevas alertas!',
                content: (<Typography.Paragraph>
            Hay nuevas alertas disponibles, ¿quieres ir a verlas ahora?
          </Typography.Paragraph>),
                onOk: () => {
                    setShouldShow(true);
                    history.push('/alerts');
                },
                onCancel: () => {
                    setShouldShow(true);
                },
            });
            play();
        }
    }
    useEffect(() => {
        if (!firstLoad &&
            shouldShow &&
            user &&
            user.alerts &&
            user.alerts !== alertsCount) {
            setShouldShow(false);
            setAlertsCount(user.alerts);
            showModal();
        }
        if (firstLoad) {
            setFirstLoad(false);
        }
        if (alertsCount === undefined) {
            setAlertsCount(user.alerts);
        }
        if (!user && inter) {
            window.clearInterval(inter);
            setInter(undefined);
        }
    }, [user]);
    useEffect(() => {
        const interval = window.setInterval(() => {
            if (user) {
                me();
            }
        }, 30 * 1000);
        setInter(interval);
    }, []);
    useEffect(() => {
        return () => {
            window.clearInterval(inter);
        };
    }, [inter]);
    return {
        unreadAlerts: alertsCount,
    };
}
