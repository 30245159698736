import { useParams } from 'react-router';
import { Alert, Card, Col, DatePicker, Radio, Row, Spin, Typography, } from 'antd';
import esLocale from 'antd/lib/date-picker/locale/es_ES';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Title, Tooltip, } from 'chart.js';
import { endOfDay, format, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, } from 'date-fns';
import moment from 'moment';
import { Loading3QuartersOutlined } from '@ant-design/icons';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, annotationPlugin);
function useDevice(id) {
    const { get } = useContext(AuthContext);
    const [querying, setQuerying] = useState(true);
    const [fromDate, setFromDate] = useState(startOfDay(subDays(new Date(), 1)));
    const [toDate, setToDate] = useState(new Date());
    const [device, setDevice] = useState();
    async function getDeviceStats(from, to) {
        const url = `/api/stats/device/${id}?from_date=${format(startOfDay(from), "yyyyMMdd'T'HH:mm:ss")}&to_date=${format(endOfDay(to), "yyyyMMdd'T'HH:mm:ss")}`;
        return await get(url);
    }
    useEffect(() => {
        ;
        (async () => {
            try {
                setQuerying(true);
                const response = await getDeviceStats(fromDate, toDate);
                if (response.ok) {
                    setDevice(await response.json());
                }
            }
            finally {
                setQuerying(false);
            }
        })();
    }, [fromDate, toDate]);
    return {
        device,
        querying,
        setFromDate,
        setToDate,
        fromDate,
        toDate,
    };
}
export default function UserDevice() {
    const { id } = useParams();
    const { device, querying, setFromDate, setToDate, fromDate, toDate } = useDevice(id);
    const [chosenRange, setChosenRange] = useState('today');
    const labels = (device?.samples ?? []).map(({ timestamp }) => timestamp);
    let graphYAxisValues = (device?.samples ?? []).map(function (sample) {
        return sample.value;
    });
    let graphMinYAxisValue = 0;
    let graphMaxYAxisValue = 0;
    if (graphYAxisValues.length > 0) {
        graphMinYAxisValue = Math.min(...graphYAxisValues);
        graphMaxYAxisValue = Math.max(...graphYAxisValues);
    }
    /*
      if (
        device?.type.graphMinYAxisValue &&
        device?.type.graphMinYAxisValue < graphMinYAxisValue
      ) {
        graphMinYAxisValue = device?.type.graphMinYAxisValue
      }

      if (
        device?.type.graphMaxYAxisValue &&
        device?.type.graphMaxYAxisValue > graphMaxYAxisValue
      ) {
        graphMaxYAxisValue = device?.type.graphMaxYAxisValue
      }
    */
    let stepSize = Math.abs((Math.floor((Math.abs(graphMaxYAxisValue) - Math.abs(graphMinYAxisValue)) / 100) *
        100) /
        5);
    stepSize = Math.max(stepSize, 5);
    graphMinYAxisValue = Math.floor(graphMinYAxisValue / stepSize) * stepSize;
    graphMaxYAxisValue = Math.ceil(graphMaxYAxisValue / stepSize) * stepSize;
    let options = {
        responsive: true,
        spanGaps: false,
        tension: 0.45,
        maintainAspectRatio: false,
        scales: {
            y: {
                display: true,
                title: {
                    display: true,
                    text: device?.type.units,
                },
                min: graphMinYAxisValue,
                max: graphMaxYAxisValue,
                ticks: {
                    stepSize: stepSize,
                },
            },
        },
        plugins: {},
    };
    if (device) {
        if (device.type.minGraphValue) {
            const { minGraphValue } = device.type;
            options.plugins = {
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            borderDash: [5, 5],
                            yMin: minGraphValue,
                            yMax: minGraphValue,
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 1,
                            label: {
                                content: 'Riesgo de incendio bajo',
                                enabled: true,
                                position: 'end',
                            },
                        },
                    },
                },
            };
        }
        if (device.type.maxGraphValue) {
            const { maxGraphValue } = device.type;
            options.plugins = {
                ...(options.plugins || {}),
                annotation: {
                    ...(options.plugins?.annotation || {}),
                    annotations: {
                        ...(options.plugins?.annotation?.annotations || {}),
                        line2: {
                            type: 'line',
                            borderDash: [5, 5],
                            yMin: maxGraphValue,
                            yMax: maxGraphValue,
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 1,
                            label: {
                                content: 'Riesgo de incendio alto',
                                enabled: true,
                                position: 'end',
                            },
                        },
                    },
                },
            };
        }
        if (device.type.otherGraphValue) {
            const { otherGraphValue } = device.type;
            options.plugins = {
                ...(options.plugins || {}),
                annotation: {
                    ...(options.plugins?.annotation || {}),
                    annotations: {
                        ...(options.plugins?.annotation?.annotations || {}),
                        line3: {
                            type: 'line',
                            borderDash: [5, 5],
                            yMin: otherGraphValue,
                            yMax: otherGraphValue,
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 1,
                            label: {
                                content: 'Riesgo de incendio muy alto',
                                enabled: true,
                                position: 'end',
                            },
                        },
                    },
                },
            };
        }
    }
    options.plugins.datalabels = { display: false };
    const data = {
        labels,
        datasets: [
            {
                label: device?.device.name ?? device?.type.name,
                data: (device?.samples ?? [])
                    .filter(sample => sample.value !== null)
                    .map(sample => ({
                    x: sample.timestamp,
                    y: sample.value,
                })),
                borderColor: device?.type.color,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    function handleChangeRange(range) {
        setChosenRange(range);
        switch (range) {
            case 'today':
                setFromDate(startOfDay(new Date()));
                break;
            case 'this-week':
                setFromDate(startOfWeek(new Date()));
                break;
            case 'this-month':
                setFromDate(startOfMonth(new Date()));
                break;
            case 'this-year':
                setFromDate(startOfYear(new Date()));
                break;
        }
        setToDate(new Date());
    }
    return querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
                    Se están cargando los datos de las estaciones.
                    <Spin indicator={<Loading3QuartersOutlined />}/>
                </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
            <Row align="top">
                <Typography.Title className="gTitle">
                    {device?.station.name} / {device?.device.name ?? device?.type.name}
                </Typography.Title>
            </Row>

            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Col span={24} className="gDashboardZone">
                    <Card id="gDeviceDateSelector">
                        <Radio.Group value={chosenRange} onChange={e => {
            handleChangeRange(e.target.value);
        }}>
                            <Radio.Button value="today">Hoy</Radio.Button>
                            <Radio.Button value="this-week">Esta semana</Radio.Button>
                            <Radio.Button value="this-month">Este mes</Radio.Button>
                            <Radio.Button value="this-year">Este año</Radio.Button>
                        </Radio.Group>
                        <DatePicker.RangePicker allowEmpty={[false, false]} allowClear={false} disabledDate={date => date.isAfter(moment(new Date()))} onChange={dates => {
            if (dates) {
                setFromDate(dates[0].toDate());
                setToDate(dates[1].toDate());
                setChosenRange(null);
            }
        }} format="DD/MM/YYYY" bordered={false} locale={esLocale} value={[moment(fromDate), moment(toDate)]}/>
                    </Card>
                </Col>
            </Row>
            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Col span={24} className="gDashboardZone">
                    <Card className="deviceChart">
                        <Line data={data} options={options}/>
                    </Card>
                </Col>
            </Row>
        </>);
}
