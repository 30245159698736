import { Alert, Card, Col, Row, Space, Spin, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import useAuth from '../hooks/useAuth';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { WindRoseControl } from '../maps/controls/rose-wind';
import { useHistory } from 'react-router';
mapboxgl.accessToken = process.env.MIX_MAPBOX_APIKEY || '';
function StationTypeFormatter(value) {
    switch (value) {
        case 'meteo':
            return 'Estación meteorológica';
        case 'bio':
            return 'Condiciones biomasa';
        case 'smoke':
            return 'Detección de humo';
        case 'ambiental':
            return 'Ambiental';
        case 'fwi':
            return 'Riesgo de incendio';
        case 'zeus':
            return 'Integración ZEUS API';
        default:
            return value;
    }
}
function Map({ areas, zonesStations, }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            areas.forEach((area, i) => {
                zonesStations.length
                    ? zonesStations[i].length
                        ? zonesStations[i]
                            .filter(station => station.position)
                            .forEach(({ position }, i) => {
                            if (position.lat > north) {
                                north = position.lat;
                            }
                            if (position.lat < south) {
                                south = position.lat;
                            }
                            if (position.lng > east) {
                                east = position.lng;
                            }
                            if (position.lng < west) {
                                west = position.lng;
                            }
                        })
                        : []
                    : [];
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 13,
                interactive: true,
            });
            map.current.on('load', () => {
                areas.forEach((area, i) => {
                    const colorSteps = [
                        '#FF2E2E',
                        '#FCFF2E',
                        '#2EC0FF',
                        '#5A2EFF',
                        '#FCFF2E',
                        '#EF2EFF',
                    ];
                    const features = zonesStations[i].length
                        ? zonesStations[i]
                            .filter(station => station.position)
                            .map(station => ({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [station.position.lng, station.position.lat],
                            },
                            properties: {
                                title: `${station.position.lng} ${station.position.lat}`,
                            },
                        }))
                        : [];
                    map.current.addSource(`zone-${i}-stations`, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features,
                        },
                    });
                    features.forEach((feature, n) => {
                        const el = document.createElement('div');
                        el.className = `station station--${zonesStations[i][n].type}`;
                        el.addEventListener('click', () => {
                            history.push(`/stations/${zonesStations[i][n].id}`);
                        });
                        let html = zonesStations[i][n].name;
                        let popup = new mapboxgl.Popup({
                            offset: 25,
                            closeButton: false,
                        }).setHTML(html);
                        let marker = new mapboxgl.Marker(el)
                            .setLngLat(feature.geometry.coordinates)
                            .setPopup(popup)
                            .addTo(map.current);
                        el.addEventListener('mouseenter', () => {
                            marker.togglePopup();
                        });
                        el.addEventListener('mouseleave', () => {
                            marker.togglePopup();
                        });
                    });
                });
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([
                    west - (west - east) / 2,
                    south + (north - south) / 2,
                ]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    });
    return (<div>
            <div ref={mapContainer} style={{
            height: 400,
            width: 'calc(100% - 2rem)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>
        </div>);
}
function ZoneValueFormatterFWI(value) {
    let range = 'EXTREMO';
    if (value < 4) {
        range = 'BAJO';
    }
    else if (value < 18) {
        range = 'MODERADO';
    }
    else if (value < 28) {
        range = 'ALTO';
    }
    else if (value < 39) {
        range = 'MUY ALTO';
    }
    return range;
}
function ZoneValueFormatterWindDirection(value) {
    let steps = Math.ceil(value / 11.25);
    let dir = 'N';
    if (steps <= 1 || steps > 31) {
        dir = 'N';
    }
    else if (steps > 1 && steps <= 3) {
        dir = 'NNE';
    }
    else if (steps > 3 && steps <= 5) {
        dir = 'NE';
    }
    else if (steps > 5 && steps <= 7) {
        dir = 'ENE';
    }
    else if (steps > 7 && steps <= 8) {
        dir = 'E';
    }
    else if (steps > 9 && steps <= 11) {
        dir = 'ESE';
    }
    else if (steps > 11 && steps <= 13) {
        dir = 'SE';
    }
    else if (steps > 13 && steps <= 15) {
        dir = 'SSE';
    }
    else if (steps > 15 && steps <= 17) {
        dir = 'S';
    }
    else if (steps > 17 && steps <= 19) {
        dir = 'SSW';
    }
    else if (steps > 19 && steps <= 21) {
        dir = 'SW';
    }
    else if (steps > 21 && steps <= 23) {
        dir = 'WSW';
    }
    else if (steps > 23 && steps <= 25) {
        dir = 'W';
    }
    else if (steps > 25 && steps <= 27) {
        dir = 'WNW';
    }
    else if (steps > 27 && steps <= 29) {
        dir = 'NW';
    }
    else if (steps > 29 && steps <= 31) {
        dir = 'NNW';
    }
    return dir;
}
function ZoneInfo({ name, stats }) {
    return (<Card title={name} className="gDashboardCardGrid">
            {Object.values(stats).map(stat => (<Card.Grid key={stat.name} className={`gDashboardCard gDashboardCard-${stat.type}`} hoverable={false}>
                    <Space direction="vertical" align="center" className="gDashboardCardBody">
                        <Typography.Title level={5} className="gDashboardCardTitle">
                            {stat.name}
                        </Typography.Title>
                        <Typography.Paragraph className="gDashboardCardValue">
                            <Typography.Text className="gDashboardCardValueNumber">
                                {stat.type === 'wind_direction'
                ? ZoneValueFormatterWindDirection(stat.value)
                : stat.type === 'fwi'
                    ? ZoneValueFormatterFWI(stat.value)
                    : stat.value ?? 'ND'}
                            </Typography.Text>
                            <Typography.Text className="gDashboardCardValueUnit">
                                {stat.type === 'fwi' ? stat.value : stat.unit}
                            </Typography.Text>
                        </Typography.Paragraph>

                        {stat.isAggregate ? (<Space direction="horizontal" className={`gDashboardCardAggregate ${name}`}>
                                <Typography className="gDashboardCardAggregateValue">
                                    <Typography.Text className="gDashboardCardAggregateValueLabel">
                                        m&iacute;n:
                                    </Typography.Text>
                                    <Typography.Text className="gDashboardCardAggregateValueNumber">
                                        {stat.min ?? 'ND'}
                                    </Typography.Text>
                                </Typography>
                                <Typography className="gDashboardCardAggregateValue">
                                    <Typography.Text className="gDashboardCardAggregateValueLabel">
                                        m&aacute;x:
                                    </Typography.Text>
                                    <Typography.Text className="gDashboardCardAggregateValueNumber">
                                        {stat.max ?? 'ND'}
                                    </Typography.Text>
                                </Typography>
                            </Space>) : null}
                    </Space>
                </Card.Grid>))}
        </Card>);
}
export default function Zones() {
    const { get, tokenInfo } = useAuth();
    const [zones, setZones] = useState([]);
    const [project, setProject] = useState(null);
    const [querying, setQuerying] = useState(true);
    async function getStats() {
        try {
            setQuerying(true);
            const response = await get('/api/stats/zones');
            if (response.ok) {
                const data = await response.json();
                const _types = Object.values(data.types)
                    .filter((type) => type.showDashboardLast)
                    .sort((a, b) => +b.position - +a.position)
                    .map(({ type, name, units, showDashboardAggregate, position }) => ({
                    key: type,
                    name,
                    units,
                    isAggregate: showDashboardAggregate,
                    position: +position,
                }))
                    .reduce((acc, type) => {
                    acc[type.key] = type;
                    return acc;
                }, {});
                setProject(data.project);
                setZones(data.zones.map((zone) => ({
                    name: zone.info.name,
                    area: zone.info.area,
                    stations: zone.stations,
                    stats: zone.last.map((measure) => ({
                        name: _types[measure.type].name,
                        isAggregate: _types[measure.type].isAggregate,
                        value: measure.value,
                        min: _types[measure.type].isAggregate
                            ? zone.aggregate[measure.type].minimum
                            : 0,
                        max: _types[measure.type].isAggregate
                            ? zone.aggregate[measure.type].maximum
                            : 0,
                        unit: _types[measure.type].units,
                        type: measure.type,
                    })),
                })));
            }
        }
        finally {
            setQuerying(false);
        }
    }
    useEffect(() => {
        if (tokenInfo) {
            getStats().then();
        }
    }, [tokenInfo]);
    let accZones = [];
    return querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
                    Se están cargando los datos de las zonas.{' '}
                    <Spin indicator={<Loading3QuartersOutlined />}/>
                </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
            {zones.length > 0 ? (<Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                    <Col span={24} className="gDashboardZone" id="sideinfoMap">
                        <Card>
                            <Map areas={zones.map(({ area }) => area)} zonesStations={zones.map(({ stations }) => stations)}/>

                            <div className="map-legend">
                                {zones
                .flatMap(zone => zone.stations.length
                ? zone.stations.reduce((acc, station) => {
                    if (acc.find(s => station.type === s.type) === undefined) {
                        acc.push(station);
                    }
                    return acc;
                }, accZones)
                : [])
                .reduce((acc, station) => {
                if (acc.find(s => station.type === s.type) === undefined) {
                    acc.push(station);
                }
                return acc;
            }, [])
                .map(station => (<div className="map-legend--item">
                                            <div className={`map-legend--item-dot station--${station.type}`}></div>
                                            <div className="map-legend--item-text">
                                                {StationTypeFormatter(station.type)}
                                            </div>
                                        </div>))}
                            </div>
                        </Card>
                    </Col>
                </Row>) : null}

            <Row justify="space-around" align="middle" className="gDashboardZones">
                {zones.map((zone, i) => (<Col key={i} className="gDashboardZone">
                        <ZoneInfo {...zone}/>
                    </Col>))}
            </Row>
        </>);
}
