import { createContext } from 'react';
import useUser from '../hooks/useUser';
export const AuthContext = createContext({
    isAuthenticated: false,
    querying: false,
    logout: () => {
    },
    login: (u, p) => Promise.resolve(),
    me: () => {
    },
    user: {},
    tokenInfo: {
        token: '',
        tokenType: '',
    },
    get: () => Promise.resolve(null),
    post: () => Promise.resolve(null),
    projects: [],
    chooseProject: (id) => null,
    clearChosenProject: () => null,
    chosenProject: undefined,
});
export default function ProvideAuth({ children }) {
    const { user, login, logout, isAuthenticated, tokenInfo, querying, me, projects, chooseProject, clearChosenProject, chosenProject, get, post, } = useUser();
    return (<AuthContext.Provider value={{
            user,
            login,
            logout,
            isAuthenticated,
            tokenInfo,
            querying,
            me,
            get,
            post,
            projects,
            chooseProject,
            clearChosenProject,
            chosenProject,
        }}>
            {children}
        </AuthContext.Provider>);
}
