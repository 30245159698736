import { Alert, Card, Col, Row, Spin, Table, Typography } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { useParams } from 'react-router';
import { EyeOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Chart } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip, BarController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarController, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);
function useStation(id) {
    const { get } = useContext(AuthContext);
    const [station, setStation] = useState();
    async function getStation() {
        return await get(`/api/stats/station/${id}`);
    }
    useEffect(() => {
        ;
        (async () => {
            try {
                const response = await getStation();
                if (response.ok) {
                    setStation(await response.json());
                }
            }
            finally {
            }
        })();
    }, []);
    return station;
}
function Map({ position: { lat, lng }, station, name, }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [lng, lat],
                interactive: true,
                zoom: 14,
            });
            map.current.on('load', () => {
                const features = [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [lng, lat],
                        },
                    },
                ];
                map.current.addSource(`station`, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features,
                    },
                });
                features.forEach((feature, i) => {
                    const el = document.createElement('div');
                    el.className = `station station--${station.station.type}`;
                    let html = station.station.name;
                    let popup = new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                    }).setHTML(html);
                    let marker = new mapboxgl.Marker(el)
                        .setLngLat(feature.geometry.coordinates)
                        .setPopup(popup)
                        .addTo(map.current);
                    el.addEventListener('mouseenter', () => {
                        marker.togglePopup();
                    });
                    el.addEventListener('mouseleave', () => {
                        marker.togglePopup();
                    });
                });
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([lng, lat]);
                map.current.fitBounds([
                    [lng - 0.005, lat - 0.005],
                    [lng + 0.005, lat + 0.005],
                ], { padding: 5 });
            });
        }
    });
    return (<div ref={mapContainer} style={{
            height: 500,
            boxSizing: 'content-box',
            width: 'calc(100%-1em)',
            margin: 'auto',
            padding: '0 1em 0 0',
        }}/>);
}
function DevicesList({ devices, types }) {
    return (<Table dataSource={devices.map(({ info, aggregate, last }) => ({
            key: info.id,
            id: info.id,
            name: info.name ? info.name : types[info.type] ? types[info.type].name : info.type,
            min: aggregate.minimum ?? 'ND',
            max: aggregate.maximum ?? 'ND',
            average: aggregate.average ?? 'ND',
            last: last.value ?? 'ND',
        }))} pagination={false}>
            <Table.Column title="Variable" dataIndex="name"/>
            <Table.Column title="Actual" dataIndex="last" align="right"/>
            <Table.Column title="Mín" dataIndex="min" align="right"/>
            <Table.Column title="Max" dataIndex="max" align="right"/>
            <Table.Column title="Media" dataIndex="average" align="right"/>

            <Table.Column title="" render={({ id }) => (<Link to={`/devices/${id}`}>
                        <EyeOutlined title="Ver gráfica"/> Ver gráfica
                    </Link>)}/>
        </Table>);
}
export function TwoDatasetsChart({ options, graph, types }) {
    const { datasets, labels } = graph;
    const scales = datasets.reduce((acc, { type: { type, units }, minAxisY, maxAxisY, stepAxisY }, i) => {
        acc[type] = {
            type: 'linear',
            display: type !== 'wind_direction',
            position: i % 2 ? 'right' : 'left',
            title: {
                display: true,
                text: units,
            },
            min: minAxisY,
            max: maxAxisY,
            ticks: {
                stepSize: stepAxisY,
            },
        };
        return acc;
    }, {});
    const plugins = {};
    const hasWindDirectionChart = datasets.some(({ chartType }) => chartType === 'arrows');
    if (hasWindDirectionChart) {
        plugins.datalabels = {
            color: types.wind_direction.color,
            borderRadius: 50,
            font: {
                size: 28,
            },
            formatter: (value, context) => {
                return context.dataset.yAxisID === 'wind_direction' &&
                    value.y !== undefined &&
                    value.y !== null
                    ? '\u27a3'
                    : '';
            },
            rotation: (context) => {
                const dataset = context.dataset;
                const value = dataset?.data?.[context.dataIndex];
                if (dataset.yAxisID !== 'wind_direction' || !value.y)
                    return 0;
                let steps = Math.ceil(value.y / 11.25);
                if (steps <= 1 || steps > 31) {
                    return 22.5 * 0 - 90;
                }
                if (steps > 1 && steps <= 3) {
                    return 22.5 * 1 - 90;
                }
                if (steps > 3 && steps <= 5) {
                    return 22.5 * 2 - 90;
                }
                if (steps > 5 && steps <= 7) {
                    return 22.5 * 3 - 90;
                }
                if (steps > 7 && steps <= 8) {
                    return 22.5 * 4 - 90;
                }
                if (steps > 9 && steps <= 11) {
                    return 22.5 * 5 - 90;
                }
                if (steps > 11 && steps <= 13) {
                    return 22.5 * 6 - 90;
                }
                if (steps > 13 && steps <= 15) {
                    return 22.5 * 7 - 90;
                }
                if (steps > 15 && steps <= 17) {
                    return 22.5 * 8 - 90;
                }
                if (steps > 17 && steps <= 19) {
                    return 22.5 * 9 - 90;
                }
                if (steps > 19 && steps <= 21) {
                    return 22.5 * 10 - 90;
                }
                if (steps > 21 && steps <= 23) {
                    return 22.5 * 11 - 90;
                }
                if (steps > 23 && steps <= 25) {
                    return 22.5 * 12 - 90;
                }
                if (steps > 25 && steps <= 27) {
                    return 22.5 * 13 - 90;
                }
                if (steps > 27 && steps <= 29) {
                    return 22.5 * 14 - 90;
                }
                return 22.5 * 15 - 90;
            },
            padding: 0,
        };
    }
    else {
        plugins.datalabels = {
            display: false,
        };
    }
    options = { ...options, plugins, scales };
    return (<Chart type="line" options={options} data={{
            labels,
            datasets: datasets.map(({ type: { type, name, color }, data, chartType }) => ({
                type: chartType === 'arrows' ? 'bar' : chartType,
                label: name,
                data,
                borderColor: chartType === 'arrows' ? '#fff0' : color,
                backgroundColor: chartType === 'arrows' ? '#fff0' : color,
                pointBackgroundColor: chartType === 'arrows' ? '#fff0' : color,
                yAxisID: type,
            })),
        }}/>);
}
function ChartsRow({ station }) {
    let options = {
        responsive: true,
        spanGaps: false,
        tension: 0.5,
        options: {
            scales: {},
        },
        plugins: {},
    };
    return (<Row gutter={20} justify="space-around" style={{ margin: '10px' }} id="gStationCharts">
            {station.graphs.map((graph, i) => (<Col key={i} className="gStationChartCol">
                    <Card className="gStationChart">
                        <TwoDatasetsChart options={options} graph={graph} types={station.types}/>
                    </Card>
                </Col>))}
        </Row>);
}
function Loading() {
    return (<Alert message="Cargando datos..." description={<Typography.Text>
                    Se están cargando los datos de la estación.
                    <Spin indicator={<Loading3QuartersOutlined />}/>
                </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>);
}
function StationInfo({ station, types }) {
    return (<Row justify="center" style={{ padding: '1rem' }}>
            <Col span={24} className="gStation">
                <Card title={station.station.name}>
                    <Row className="gStationBody">
                        <Col className="gStationMap">
                            <Map position={station.station.position} station={station} name={station.station.name}/>

                            <div className="map-legend">
                                <div className="map-legend--item">
                                    <div className={`map-legend--item-dot station--${station.station.type}`}></div>
                                    <div className="map-legend--item-text">
                                        {station.station.type.slice(0, 1).toUpperCase()}
                                        {station.station.type.slice(1)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="gStationTable">
                            <DevicesList devices={station?.devices || []} types={types}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>);
}
export default function UserStation() {
    const { id } = useParams();
    const station = useStation(id);
    return !station ? (<Loading />) : (<>
            <StationInfo station={station} types={station.types}/>

            <ChartsRow station={station}/>

            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Alert message="Valores mostrados de las últimas 24 horas." type="info" showIcon/>
            </Row>
        </>);
}
