import * as React from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'antd';
import Collection from '../components/Collection';
import useLoadCollection from '../hooks/useObjectsCollection';
import userFields from '../fields/user';
import ItemDialog, { ItemAction } from '../components/ItemDialog';
import { PlusOutlined } from '@ant-design/icons';
export default function Users() {
    const { querying, collection, reloadCollection } = useLoadCollection('user');
    const [showCreationDialog, setShowCreationDialog] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [userIdToUpdate, setUserIdToUpdate] = useState(null);
    function handleCreationDialogShow() {
        setShowCreationDialog(true);
    }
    function handleUpdateDialogShow(id) {
        return () => {
            setUserIdToUpdate(id);
        };
    }
    function handleDeleteDialogShow(id) {
        return () => {
            setUserIdToDelete(id);
        };
    }
    function onCreateProjectDialogOpenHandler() {
        setShowCreationDialog(true);
    }
    return (<>
      <Row>
        <Col></Col>
      </Row>
      <Row justify="center">
        <Col>
          <ItemDialog type="user" id={userIdToUpdate} fields={userFields} action={ItemAction.Update} title={() => 'Actualizar usuario'} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                setUserIdToUpdate(null);
            })();
        }} onCancel={() => {
            setUserIdToUpdate(null);
        }}/>
          <ItemDialog type="user" id={null} fields={userFields} action={ItemAction.Create} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                setShowCreationDialog(false);
            })();
        }} onCancel={() => {
            setUserIdToUpdate(null);
        }} title={() => 'Actualizar usuario'}/>
          <ItemDialog type="user" id={userIdToDelete} fields={userFields} action={ItemAction.Delete} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                setUserIdToDelete(null);
            })();
        }} onCancel={() => {
            setUserIdToDelete(null);
        }} title={() => 'Eliminar usuario'}/>
          <ItemDialog visible={showCreationDialog} type="user" id={null} fields={userFields} action={ItemAction.Create} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                setShowCreationDialog(false);
            })();
        }} onCancel={() => {
            setShowCreationDialog(false);
        }} title={() => 'Crear un nuevo usuario'}/>
          <Collection querying={querying} collection={collection} fields={userFields} onItemDeleteRequest={handleDeleteDialogShow} onItemUpdateRequest={handleUpdateDialogShow}/>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button shape="circle" size="large" onClick={onCreateProjectDialogOpenHandler} style={{ right: 10, bottom: 10, position: 'absolute' }} icon={<PlusOutlined />}/>
        </Col>
      </Row>
    </>);
}
