import useAuth from './useAuth';
export default function useUser() {
    const { user, logout, login, tokenInfo, querying, me, projects, chooseProject, clearChosenProject, chosenProject, get, post, } = useAuth();
    const { projectId = undefined } = user || {};
    return {
        user,
        me,
        login,
        logout,
        isAuthenticated: !!user,
        tokenInfo,
        querying,
        projects,
        chooseProject,
        clearChosenProject,
        chosenProject,
        get,
        post,
    };
}
