import { Card, Typography } from 'antd';
import { ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
function getLevelRange(type, value) {
    let level = 'null';
    if (type === 'temperature') {
        level = getLevelRangeTemperature(value);
    }
    else if (type === 'humidity') {
        level = getLevelRangeHumidity(value);
    }
    else if (type === 'rain_month') {
        level = getLevelRangeRainMonth(value);
    }
    else if (type === 'wind_speed') {
        level = getLevelRangeWindSpeed(value);
    }
    else if (type === 'wind_direction') {
        level = getLevelRangeWindDirection(value);
    }
    else if (type === 'fwi') {
        level = getLevelRangeFWI(value);
    }
    return level;
}
function getLevelRangeTemperature(value) {
    let range = 'very-low';
    if (value >= 35) {
        range = 'very-high';
    }
    else if (value >= 30) {
        range = 'high';
    }
    else if (value >= 25) {
        range = 'moderate';
    }
    else if (value >= 10) {
        range = 'low';
    }
    return range;
}
function getLevelRangeHumidity(value) {
    let range = 'very-low';
    if (value >= 75) {
        range = 'very-high';
    }
    else if (value >= 50) {
        range = 'moderate';
    }
    else if (value >= 25) {
        range = 'low';
    }
    return range;
}
function getLevelRangeRainMonth(value) {
    return 'very-high';
}
function getLevelRangeWindSpeed(value) {
    return 'high';
}
function getLevelRangeWindDirection(value) {
    return 'very-low';
}
function getLevelRangeFWI(value) {
    let range = 'very-low';
    if (value >= 39) {
        range = 'very-high';
    }
    else if (value >= 28) {
        range = 'high';
    }
    else if (value >= 18) {
        range = 'moderate';
    }
    else if (value >= 4) {
        range = 'low';
    }
    return range;
}
function ZoneValueFormatterFWI(value) {
    let range = 'EXTREMO';
    if (value < 4) {
        range = 'BAJO';
    }
    else if (value < 18) {
        range = 'MODERADO';
    }
    else if (value < 28) {
        range = 'ALTO';
    }
    else if (value < 39) {
        range = 'MUY ALTO';
    }
    return range;
}
function ZoneValueFormatterWindDirection(value) {
    let steps = Math.ceil(value / 11.25);
    let dir = 'N';
    if (steps <= 1 || steps > 31) {
        dir = 'N';
    }
    else if (steps > 1 && steps <= 3) {
        dir = 'NNE';
    }
    else if (steps > 3 && steps <= 5) {
        dir = 'NE';
    }
    else if (steps > 5 && steps <= 7) {
        dir = 'ENE';
    }
    else if (steps > 7 && steps <= 8) {
        dir = 'E';
    }
    else if (steps > 9 && steps <= 11) {
        dir = 'ESE';
    }
    else if (steps > 11 && steps <= 13) {
        dir = 'SE';
    }
    else if (steps > 13 && steps <= 15) {
        dir = 'SSE';
    }
    else if (steps > 15 && steps <= 17) {
        dir = 'S';
    }
    else if (steps > 17 && steps <= 19) {
        dir = 'SSW';
    }
    else if (steps > 19 && steps <= 21) {
        dir = 'SW';
    }
    else if (steps > 21 && steps <= 23) {
        dir = 'WSW';
    }
    else if (steps > 23 && steps <= 25) {
        dir = 'W';
    }
    else if (steps > 25 && steps <= 27) {
        dir = 'WNW';
    }
    else if (steps > 27 && steps <= 29) {
        dir = 'NW';
    }
    else if (steps > 29 && steps <= 31) {
        dir = 'NNW';
    }
    return dir;
}
export function MeasuresResume({ measures }) {
    return measures.length ? (<Card id="dashboard-cards">
            {Object.values(measures).map(measure => (<Card.Grid className={'dashboard-card dashboard-' +
                measure.type +
                ' dashboard-' +
                measure.type +
                '-' +
                getLevelRange(measure.type, measure.value)} key={measure.name} hoverable={false}>
                    <Typography.Title level={5} className="gDashboardCardTitle">
                        {measure.name}
                    </Typography.Title>
                    <Typography.Paragraph className={'gDashboardCardValue gDashboardCard--' + measure.type}>

                        {measure.type == 'wind_direction' ? <Typography.Text className={'gDashboardCardValueNumberSymbol gDashboardCardValueNumberSymbol--' + ZoneValueFormatterWindDirection(measure.value)}>
                            <ArrowUpOutlined />
                        </Typography.Text> : ''}
                        <Typography.Text className={'gDashboardCardValueNumber'}>
                            {measure.type == 'wind_direction'
                ? ZoneValueFormatterWindDirection(measure.value)
                : measure.type == 'fwi'
                    ? ZoneValueFormatterFWI(measure.value)
                    : measure.value
                        ? measure.value
                        : '0.0'}
                        </Typography.Text>
                        <Typography.Text className="gDashboardCardValueUnit">
                            {measure.type == 'fwi'
                ? measure.value
                : measure.type !== 'wind_direction'
                    ? measure.unit
                    : ''}
                        </Typography.Text>
                    </Typography.Paragraph>
                </Card.Grid>))}
        </Card>) : null;
}
