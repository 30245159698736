import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { useHistory } from 'react-router';
import { WindRoseControl } from './controls/rose-wind';
mapboxgl.accessToken = process.env.MIX_MAPBOX_APIKEY || '';
export function Map({ installation, stations: _stations, className, }) {
    const stations = installation?.stations || _stations;
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            stations?.forEach(({ position }, i) => {
                if (position.lat > north) {
                    north = position.lat;
                }
                if (position.lat < south) {
                    south = position.lat;
                }
                if (position.lng > east) {
                    east = position.lng;
                }
                if (position.lng < west) {
                    west = position.lng;
                }
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 13,
                interactive: true,
            });
            map.current.on('load', () => {
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([west - (west - east) / 2, south + (north - south) / 2]);
                /*map.current!.fitBounds(
                    [
                        [west, south],
                        [east, north],
                    ],
                    { padding: 50 }
                )*/
                const features = stations?.map(station => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [station.position.lng, station.position.lat],
                    },
                }));
                map.current.addSource(`stations`, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features,
                    },
                });
                features.forEach((feature, i) => {
                    const el = document.createElement('div');
                    const station = stations[i];
                    el.className = `station station--${station.type}`;
                    el.addEventListener('click', () => {
                        history.push(`/stations/${station.id}`);
                    });
                    let html = station.name;
                    let popup = new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false
                    }).setHTML(html);
                    let marker = new mapboxgl.Marker(el)
                        .setLngLat(feature.geometry.coordinates)
                        .setPopup(popup)
                        .addTo(map.current);
                    el.addEventListener('mouseenter', () => {
                        marker.togglePopup();
                    });
                    el.addEventListener('mouseleave', () => {
                        marker.togglePopup();
                    });
                });
            });
        }
    });
    useEffect(() => {
        if ((stations || []).length > 0) {
            let north = -90, south = 90, east = -180, west = 180;
            stations?.forEach(({ position }, i) => {
                if (position.lat > north) {
                    north = position.lat;
                }
                if (position.lat < south) {
                    south = position.lat;
                }
                if (position.lng > east) {
                    east = position.lng;
                }
                if (position.lng < west) {
                    west = position.lng;
                }
            });
            map.current.fitBounds([
                [west, south],
                [east, north],
            ], { padding: 50 });
        }
    }, [JSON.stringify(stations)]);
    return (<div id="map-container">
            <div id="map" ref={mapContainer} className={className} style={{
            height: '480px',
            width: 'calc(100%)',
            margin: '0',
            boxSizing: 'content-box',
        }}/>
        </div>);
}
