import * as React from 'react';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Button, Col, Form, Image, Input, Modal, Row } from 'antd';
import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import ProjectChooserMenu from '../components/ProjectChooseMenu';
export default function Login() {
    const { login, chooseProject, user, chosenProject, me } = useContext(AuthContext);
    const [querying, setQuerying] = useState(false);
    function onSubmit({ username, password }) {
        ;
        (async () => {
            setQuerying(true);
            try {
                const response = await login(username, password);
                if (!response.ok) {
                    let message = 'Se ha producido un error al intentar acceder al aplicativo';
                    if (response.status === 404) {
                        message =
                            'El usuario y/o la contraseña que ha introducido, no son correctos.';
                    }
                    else if (response.status === 401) {
                        message =
                            'Su licencia a caducado, para renovarla póngase en contacto con nosotros.';
                    }
                    Modal.error({
                        okText: 'De acuerdo',
                        content: message,
                    });
                }
            }
            finally {
                setQuerying(false);
            }
        })();
    }
    return (<Row justify="center" align="middle">
      <ProjectChooserMenu />
      <Col className="gLoginBox">
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onSubmit} layout="vertical">
          <Row justify="center" className="gLoginBoxLogo">
            <Image src={'/logo/' + window.location.hostname + '.jpg'}/>
          </Row>

          <Row justify="center" className="gLoginBoxInput">
            <Form.Item name="username" required hasFeedback wrapperCol={{ span: 24 }}>
              <Input name="username" size="large" disabled={querying} placeholder="correu@sideinfo.es" prefix={<UserOutlined />} autoComplete="username"/>
            </Form.Item>
          </Row>

          <Row justify="center" className="gLoginBoxInput">
            <Form.Item name="password" required hasFeedback wrapperCol={{ span: 24 }}>
              <Input.Password name="password" size="large" disabled={querying} placeholder="password" prefix={<KeyOutlined />} autoComplete="current-password"/>
            </Form.Item>
          </Row>

          <Row justify="center" className="gLoginBoxButton">
            <Button htmlType="submit" type="primary" disabled={querying} loading={querying && { delay: 100 }}>
              Entrar
            </Button>
          </Row>
        </Form>
      </Col>
    </Row>);
}
