import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import { Card, Col, DatePicker, Row, Table, Typography } from 'antd';
import moment from 'moment';
import esLocale from 'antd/lib/date-picker/locale/es_ES';
function useAlerts() {
    const { get } = useContext(AuthContext);
    const [fromDate, setFromDate] = useState(startOfDay(subDays(new Date(), 1)));
    const [toDate, setToDate] = useState(new Date());
    const [alerts, setAlerts] = useState([]);
    const [inter, setInter] = useState();
    async function getAlerts(from, to) {
        return await get(`/api/stats/alerts?from_date=${format(startOfDay(from), "yyyyMMdd'T'HH:mm:ss")}&to_date=${format(endOfDay(to), "yyyyMMdd'T'HH:mm:ss")}`);
    }
    async function updateAlerts() {
        const response = await getAlerts(fromDate, toDate);
        if (response.ok) {
            const _alerts = (await response.json()).map((alert) => ({
                ...alert,
                created_at: parseISO(alert.created_at),
            }));
            setAlerts(_alerts);
        }
    }
    useEffect(() => {
        updateAlerts().then();
    }, [fromDate, toDate]);
    useEffect(() => {
        setInter(window.setInterval(() => {
            updateAlerts().then();
        }, 30 * 1000));
        return () => {
            window.clearInterval(inter);
        };
    }, []);
    return { fromDate, toDate, setFromDate, setToDate, alerts };
}
export default function UserAlerts() {
    const { fromDate, toDate, setFromDate, setToDate, alerts } = useAlerts();
    return (<>
            <Row align="top">
                <Typography.Title className="gTitle">Alertas</Typography.Title>
            </Row>
            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Col span={24} className="gDashboardZone">
                    <Card>
                        <DatePicker.RangePicker allowEmpty={[false, false]} allowClear={false} disabledDate={date => date.isAfter(moment(new Date()))} onChange={dates => {
            if (dates) {
                setFromDate(dates[0].toDate());
                setToDate(dates[1].toDate());
            }
        }} format="DD/MM/YYYY" bordered={false} locale={esLocale} value={[moment(fromDate), moment(toDate)]}/>
                    </Card>
                </Col>
            </Row>
            <Row justify="space-around" align="middle" className="gDashboardZones">
                <Col span={24} className="gDashboardZone">
                    <Card>
                        <Table dataSource={alerts} pagination={false}>
                            <Table.Column dataIndex={['raw']} title="Message"/>
                            <Table.Column dataIndex={['created_at']} title="Fecha y hora" render={date => format(date, 'dd-MM-yyyy HH:mm:ss')}/>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>);
}
