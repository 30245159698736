import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import useItemInfo from '../hooks/useItemInfo';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
export var ItemAction;
(function (ItemAction) {
    ItemAction["Create"] = "create";
    ItemAction["Update"] = "update";
    ItemAction["Delete"] = "delete";
})(ItemAction || (ItemAction = {}));
function OptionsField({ optionsMapper, onChange, multiple, value, otherValues, }) {
    const [options, setOptions] = useState([]);
    const { get } = useContext(AuthContext);
    useEffect(() => {
        ;
        (async () => {
            const response = await get(optionsMapper.url);
            const rawOptions = await response.json();
            const _options = optionsMapper.mapper(rawOptions, otherValues);
            setOptions(_options);
        })();
    }, []);
    return multiple ? (<Select onChange={onChange} value={value &&
            (Array.isArray(value)
                ? value.map((val) => ({
                    label: options.find(option => option.value === val)?.label,
                    value: val,
                }))
                : JSON.parse(value).map((val) => ({
                    label: options.find(option => option.value === val)?.label,
                    value: val,
                })))} mode="multiple">
      {options.map(({ value, label }, i) => (<Select.Option key={`${value}-${i}`} value={value}>
          {label}
        </Select.Option>))}
    </Select>) : (<Select value={value} onChange={onChange} defaultValue={value}>
      {options.map(({ value, label }, i) => (<Select.Option key={`${value}-${i}`} value={value}>
          {label}
        </Select.Option>))}
    </Select>);
}
function StaticOptionsField({ options, value, onChange, }) {
    return (<Select value={value} onChange={onChange}>
      {options.map(({ value, label }) => (<Select.Option key={value} value={value}>
          {label}
        </Select.Option>))}
    </Select>);
}
export default function ItemDialog({ fields, type, id, action, onAccept, onCancel, title, parentData, visible, }) {
    const [form] = Form.useForm();
    const { item, querying, create, update, deleteItem } = useItemInfo(type, id, parentData);
    function handleAccept() {
        ;
        (async () => {
            if (isDeleteAction()) {
                await deleteItem();
            }
            else {
                let values = Object.entries((await form.validateFields())).reduce((acc, [key, value]) => {
                    if (fields.find(_field => _field.key === key)?.type === 'json') {
                        return {
                            ...acc,
                            [key]: JSON.parse(value),
                        };
                    }
                    return {
                        ...acc,
                        [key]: value,
                    };
                }, {});
                switch (action) {
                    case ItemAction.Create:
                        await create(values);
                        break;
                    case ItemAction.Update:
                        if (parentData?.key) {
                            values[parentData.key] = item.id;
                        }
                        await update(values);
                        break;
                    case ItemAction.Delete:
                        await deleteItem();
                        break;
                }
            }
            onAccept();
        })();
    }
    function handleCancel() {
        form.resetFields();
        onCancel();
    }
    useEffect(() => {
        if (item) {
            const updatedValues = Object.keys(item).reduce((acc, field) => {
                if (fields.find(_field => _field.key === field)?.type === 'json') {
                    acc[field] = JSON.stringify(item[field]);
                }
                else {
                    acc[field] = item[field];
                }
                return acc;
            }, {});
            form.setFieldsValue(updatedValues);
        }
    }, [item]);
    function isDeleteAction() {
        return action === 'delete';
    }
    return (<Modal open={!!(id || visible)} onOk={handleAccept} onCancel={onCancel} title={querying ? 'Cargando datos...' : title(item)} footer={[
            <Button onClick={handleCancel} disabled={querying} key="cancel">
          Cancelar
        </Button>,
            <Button onClick={handleAccept} disabled={querying} loading={querying} key="accept">
          Aceptar
        </Button>,
        ]}>
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        {fields.map(field => (<Form.Item required={field.required && !isDeleteAction()} label={field.label} name={field.key} hasFeedback={!isDeleteAction()} key={field.key} rules={[
                !isDeleteAction()
                    ? {
                        required: (field.required && action !== ItemAction.Update) ||
                            !field.optionalForUpdate,
                        message: 'Este campo es requerido',
                        validator: async (_, value) => {
                            if (field.validation) {
                                const isValid = await field.validation(value);
                                if (!isValid) {
                                    return Promise.reject('Valor inválido');
                                }
                            }
                        },
                    }
                    : {},
            ]}>
            {field.multiline ? (<Input.TextArea disabled={querying || isDeleteAction()} required={field.required && !isDeleteAction()} placeholder={field.placeholder || ''}/>) : field.type === 'staticOptions' ? (<StaticOptionsField options={field.options}/>) : field.type === 'options' ? (<OptionsField optionsMapper={field.optionsMapper} multiple={!!field?.multiple} otherValues={fields}/>) : field.type === 'number' ? (<InputNumber disabled={querying || isDeleteAction()} required={field.required && !isDeleteAction()} placeholder={field.placeholder || ''}/>) : field.type === 'json' ? (<Input disabled={querying || isDeleteAction()} required={field.required && !isDeleteAction()} placeholder={field.placeholder || ''}/>) : (<Input disabled={querying || isDeleteAction()} required={field.required && !isDeleteAction()} placeholder={field.placeholder || ''}/>)}
          </Form.Item>))}
      </Form>
    </Modal>);
}
