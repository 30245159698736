import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ProvideAuth, { AuthContext } from './contexts/Auth';
import Unauthenticated from './roles/Unauthenticated';
import { useContext } from 'react';
import BasicCustomer from './roles/BasicCustomer';
import Administrator from "./roles/Administrator";
function Roles() {
    const { isAuthenticated, chosenProject, user, } = useContext(AuthContext);
    if (!isAuthenticated || chosenProject === undefined) {
        return <Unauthenticated />;
    }
    if (parseInt(chosenProject) > 0) {
        return <BasicCustomer roles={user.roles}/>;
    }
    if (parseInt(chosenProject) === 0) {
        return <Administrator></Administrator>;
    }
    return <Unauthenticated />;
}
function App() {
    return (<ProvideAuth>
            <Roles />
        </ProvideAuth>);
}
export default App;
