export default [
    {
        key: 'name',
        label: 'Nombre',
        required: true,
    },
    {
        key: 'slug',
        label: 'Slug',
        required: true,
    },
    {
        key: 'description',
        label: 'Descripción',
        required: true,
        multiline: true,
    },
];
