export class WindRoseControl {
    className;
    container;
    map;
    constructor(className, container = document.createElement('div')) {
        this.className = className;
        this.container = container;
    }
    onAdd(map) {
        this.map = map;
        this.container.className = this.className;
        return this.container;
    }
    onRemove(map) {
        this.container.parentNode?.removeChild(this.container);
        this.map = undefined;
    }
}
