import { useContext, useEffect, useState } from 'react';
import { Card, Col, Image, Row } from 'antd';
import { Map } from '../maps/DefaultMap';
import { MeasuresResume } from './MeasuresResume';
import { AuthContext } from '../contexts/Auth';
function StationTypeFormatter(value) {
    switch (value) {
        case 'meteo':
            return 'Estación meteorológica';
        case 'bio':
            return 'Condiciones biomasa';
        case 'smoke':
            return 'Detección de humo';
        case 'ambiental':
            return 'Ambiental';
        case 'fwi':
            return 'Riesgo de incendio';
        case 'zeus':
            return 'Integración ZEUS API';
        default:
            return value;
    }
}
function CamsComponent(props) {
    return <>
        {props.cams.length > 0 ? (<Row>
                <Col span={24} id="cams">
                    {props.cams.map(props.callbackfn)}
                </Col>
            </Row>) : ("")}
    </>;
}
function WidgetComponent(props) {
    return <>
        {props.project?.widget && props.project?.widget?.length > 0 ? (<Col id="sideinfoWidget" xs={24} sm={24} md={24} lg={24} xl={12} xxl={8} dangerouslySetInnerHTML={{ __html: props.project?.widget }}></Col>) : null}
    </>;
}
function MapComponent(props) {
    return <Col xs={24} sm={24} md={24} lg={24} xl={props.project?.widget && props.project?.widget?.length > 0 ? 12 : 24} xxl={props.project?.widget && props.project?.widget?.length > 0 ? 16 : 24} id="sideinfoMap">
        {props.stations.length > 0 ? <Map stations={props.stations}/> : null}

        <div className="map-legend">
            {props.stations
            .reduce(props.callbackfn, [])
            .map(props.callbackfn1)}
        </div>
    </Col>;
}
export default function Dashboard() {
    const { get, tokenInfo } = useContext(AuthContext);
    const [stations, setStations] = useState([]);
    const [querying, setQuerying] = useState(true);
    const [measures, setMeasures] = useState({});
    const [cams, setCams] = useState([]);
    const [project, setProject] = useState();
    async function getStats() {
        try {
            setQuerying(true);
            const response = await get('/api/stats/dashboard');
            if (response.ok) {
                const data = await response.json();
                const _measures = data.last.reduce((acc, { value, type }) => {
                    acc[type] = {
                        value,
                        name: data.types[type].name,
                        unit: data.types[type].units,
                        type: type,
                    };
                    return acc;
                }, {});
                setStations(data.stations);
                setMeasures(_measures);
                setCams(data.cams);
                setProject(data.project);
            }
        }
        finally {
            setQuerying(false);
        }
    }
    useEffect(() => {
        if (tokenInfo) {
            ;
            (async () => {
                await getStats();
            })();
        }
    }, [tokenInfo]);
    useEffect(() => {
        const interval = setInterval(() => {
            getStats();
        }, 1 * 60 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    let accStations = [];
    return (<>
            <MeasuresResume measures={Object.values(measures)}/>

            <Row justify="space-around" align="middle" className={"gDashboardZones gDashboard--" + project?.slug} id="sideinfo">
                <Col span={24} className="gDashboardZone" id="sideinfoMap">
                    <Card className="sideinfoFlex">
                        <Row id="dashboard-row">
                            {project ?
            <MapComponent project={project} stations={stations} callbackfn={(acc, station) => {
                    if (acc.find((s) => station.type === s.type) === undefined) {
                        acc.push(station);
                    }
                    return acc;
                }} callbackfn1={station => (<div className="map-legend--item" key={station.id}>
                                        <div className={`map-legend--item-dot station--${station.type}`}></div>
                                        <div className="map-legend--item-text">
                                            {StationTypeFormatter(station.type)}
                                        </div>
                                    </div>)}/> : null}
                            {project ? <WidgetComponent project={project}/> : null}
                        </Row>
                        <CamsComponent cams={cams} callbackfn={cam => (<div id={cam.id} key={cam.id} className="cam">
                                <Image src={cam.preview}/>
                            </div>)}/>
                    </Card>
                </Col>
            </Row>
        </>);
}
