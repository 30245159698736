import { createContext } from 'react';
import useSideinfo from '../hooks/useSideinfo';
export const SideinfoContext = createContext({
    installations: [],
    performAction: () => null,
    loading: true,
    getSideinfo: () => Promise.resolve(),
    reload: 0,
});
export default function SideinfoProvider({ children, }) {
    const sideinfo = useSideinfo();
    return (<SideinfoContext.Provider value={sideinfo}>{children}</SideinfoContext.Provider>);
}
