import { Badge, Menu } from 'antd';
import { AlertOutlined, BellOutlined, BoxPlotOutlined, DashboardOutlined, ExperimentOutlined, FireOutlined, LogoutOutlined, MenuOutlined, MoreOutlined, NodeExpandOutlined, PictureOutlined, ProjectOutlined, SyncOutlined, TableOutlined, UserAddOutlined, } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import useAlerts from '../hooks/useAlerts';
import ProjectChooserMenu from './ProjectChooseMenu';
import { MapType } from "../views/MapType";
export default function BasicUserMenu({ onItemClick, roles }) {
    const { user, logout, clearChosenProject, projects } = useContext(AuthContext);
    const [current, setCurrent] = useState(location.pathname);
    const [projectChooserOpen, setProjectChooserOpen] = useState(false);
    useAlerts();
    useEffect(() => {
        return () => {
            clearChosenProject();
        };
    }, []);
    const className = 'gMenuItem';
    const onClick = onItemClick;
    function handleProjectChooserMenuOpen(state = true) {
        return () => setProjectChooserOpen(state);
    }
    let items = [];
    if (roles.includes('dashboard')) {
        items.push({
            key: '/dashboard',
            icon: <DashboardOutlined />,
            label: <Link to="/dashboard">Dashboard</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('zones')) {
        items.push({
            key: '/zones',
            icon: <BoxPlotOutlined />,
            label: <Link to="/zones">Zonas</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('meteo')) {
        items.push({
            key: '/stations/meteo',
            icon: <NodeExpandOutlined />,
            label: <Link to="/stations/meteo">Meteo</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('ambiental')) {
        items.push({
            key: '/stations/ambiental',
            icon: <PictureOutlined />,
            label: <Link to="/stations/ambiental">Ambiental</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('bio')) {
        items.push({
            key: '/stations/bio',
            icon: <TableOutlined />,
            label: <Link to="/stations/bio">Biomasa</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('smoke')) {
        items.push({
            key: '/stations/smoke',
            icon: <ProjectOutlined />,
            label: <Link to="/stations/smoke">Detección de humo</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('fwi')) {
        items.push({
            key: '/stations/fwi',
            icon: <FireOutlined />,
            label: <Link to="/stations/fwi">Riesgo de incendio</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('maps')) {
        let children = [];
        if (roles.includes('maps.voc')) {
            children.push({
                key: '/stations/map/' + MapType.VOC_ALARM_STATUS,
                icon: <SyncOutlined />,
                label: (<Link to={'/stations/map/' + MapType.VOC_ALARM_STATUS}>VOC</Link>),
                onClick,
                className,
            });
        }
        if (roles.includes('maps.temperature')) {
            children.push({
                key: '/stations/map/' + MapType.TEMPERATURE,
                icon: <SyncOutlined />,
                label: (<Link to={'/stations/map/' + MapType.TEMPERATURE}>Temperatura</Link>),
                onClick,
                className,
            });
        }
        if (roles.includes('maps.humidity')) {
            children.push({
                key: '/stations/map/' + MapType.HUMIDITY,
                icon: <SyncOutlined />,
                label: (<Link to={'/stations/map/' + MapType.HUMIDITY}>Humedad</Link>),
                onClick,
                className,
            });
        }
        if (roles.includes('maps.pressure')) {
            children.push({
                key: '/stations/map/' + MapType.ATM_PRESSURE,
                icon: <SyncOutlined />,
                label: (<Link to={'/stations/map/' + MapType.ATM_PRESSURE}>Presión atmosférica</Link>),
                onClick,
                className,
            });
        }
        items.push({
            key: 'mapas',
            title: 'Mapas',
            icon: <SyncOutlined />,
            label: <>Mapas</>,
            children: children
        });
    }
    if (roles.includes('simulations')) {
        items.push({
            key: '/simulations',
            icon: <ExperimentOutlined />,
            label: <Link to="/simulations">Simulaciones</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('pi')) {
        items.push({
            key: '/stations/pi',
            icon: <MoreOutlined />,
            label: <Link to="/stations/pi">Riego prescrito</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('sideinfo')) {
        items.push({
            key: '/sideinfo',
            icon: <UserAddOutlined />,
            label: <Link to="/sideinfo">Activar SIDEINFO</Link>,
            onClick,
            className,
        });
    }
    if (roles.includes('zeus')) {
        items.push({
            key: '/stations/zeus',
            icon: <BellOutlined />,
            label: <Link to="/stations/zeus">Monitorización SIDEINFO</Link>,
            onClick,
            className,
        });
    }
    user?.alerts
        ? items.push({
            key: '/alerts',
            icon: <AlertOutlined />,
            label: (<Badge count={user.alerts} offset={[30, 5]}><Link to="/alerts">Alertas</Link></Badge>),
            onClick,
            className,
        })
        : items.push({
            key: '/alerts',
            icon: <AlertOutlined />,
            label: <Link to="/alerts">Alertas</Link>,
            onClick,
            className,
        });
    items.push({
        key: '/project-change',
        icon: <MenuOutlined />,
        label: (<span onClick={handleProjectChooserMenuOpen(true)}>Cambiar de proyecto</span>)
    });
    items.push({
        key: '/logout',
        icon: <LogoutOutlined />,
        label: (<Link to="#" onClick={logout}>
                    Cerrar sesión
                </Link>),
        onClick,
        className,
    });
    return (<>
            <ProjectChooserMenu open={projectChooserOpen} onOk={handleProjectChooserMenuOpen(false)}/>
            <Menu mode="inline" selectedKeys={[current]} onClick={({ key }) => setCurrent(key)} defaultOpenKeys={['mapas']} items={items}/>
        </>);
}
