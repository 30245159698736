import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLoadCollection from '../hooks/useObjectsCollection';
import Collection from '../components/Collection';
import ItemDialog, { ItemAction } from '../components/ItemDialog';
import fields from '../fields/zones';
import stationsFields from '../fields/stations';
import { Col, Row } from 'antd';
import { useRouteMatch } from 'react-router';
export default function () {
    const { params: { id, child_type }, } = useRouteMatch();
    const { collection, querying, reloadCollection } = useLoadCollection(child_type ? `project/${id}/zones` : 'zone');
    const [zoneIdToDelete, setZoneIdToDelete] = useState(null);
    const [zoneIdToUpdate, setZoneIdToUpdate] = useState(null);
    const [zoneIdToAddStation, setZoneIdToAddStation] = useState(null);
    const history = useHistory();
    function handleDeleteZoneDialogOpen(id) {
        return () => {
            setZoneIdToDelete(id);
        };
    }
    function handleDeleteZoneDialogClose() {
        setZoneIdToDelete(null);
    }
    function handleUpdateZoneDialogOpen(id) {
        return () => {
            setZoneIdToUpdate(id);
        };
    }
    function handleUpdateZoneDialogClose() {
        setZoneIdToUpdate(null);
    }
    function handleCreateStationDialogOpen(id) {
        return () => {
            setZoneIdToAddStation(id);
        };
    }
    function handleCreateStationDialogClose() {
        setZoneIdToAddStation(null);
    }
    function goToStations(id) {
        return () => {
            history.push(`/zones/${id}/stations`);
        };
    }
    return (<Row justify="center" align="middle">
      <Col>
        <ItemDialog id={null} visible={!!zoneIdToAddStation} type="station" onCancel={handleCreateStationDialogClose} onAccept={() => {
            history.push('/stations');
        }} title={item => {
            return `Crear una estación en la zona "${collection.find(({ id }) => id === zoneIdToAddStation)?.name}"`;
        }} fields={stationsFields} action={ItemAction.Create} parentData={{
            id: zoneIdToAddStation,
            key: 'zone_id',
        }}/>
        <ItemDialog onCancel={handleUpdateZoneDialogClose} action={ItemAction.Update} title={item => `Actualizar la zona "${item?.name}"`} fields={fields} id={zoneIdToUpdate} type="zone" onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                handleUpdateZoneDialogClose();
            })();
        }}/>
        <ItemDialog onCancel={handleDeleteZoneDialogClose} action={ItemAction.Delete} title={item => `¿Deseas eliminar la zona "${item?.name}"?`} fields={fields} id={zoneIdToDelete} type="zone" onAccept={() => {
            reloadCollection().then(handleDeleteZoneDialogClose);
        }}/>
        <Collection querying={querying} onItemUpdateRequest={handleUpdateZoneDialogOpen} onCreateChildItemRequest={handleCreateStationDialogOpen} collection={collection} onItemDeleteRequest={handleDeleteZoneDialogOpen} fields={fields} goToChildren={goToStations}/>
      </Col>
    </Row>);
}
