import { useEffect, useState } from 'react';
import isFuture from 'date-fns/isFuture';
import diffInSeconds from 'date-fns/differenceInSeconds';
import { ClockCircleOutlined } from '@ant-design/icons';
export function TimeWorking({ lastActionTimestamp = new Date(), }) {
    const [diff, setDiff] = useState(0);
    useEffect(() => {
        if (isFuture(lastActionTimestamp)) {
            const interval = setInterval(() => {
                let diff = diffInSeconds(lastActionTimestamp, new Date());
                if (diff <= 0) {
                    setDiff(0);
                    clearInterval(interval);
                    return;
                }
                setDiff(diff);
            }, 500);
        }
    }, []);
    let _diff = diff;
    const seconds = _diff % 60;
    _diff /= 60;
    const minutes = Math.floor(_diff % 60);
    const hours = Math.floor(_diff / 60);
    return !diff ? (<></>) : (<>
      <ClockCircleOutlined /> Tiempo hasta apagado{' '}
      {`0${hours}`.split('').slice(-2).join('')}:
      {`0${minutes}`.split('').slice(-2).join('')}:
      {`0${seconds}`.split('').slice(-2).join('')}
    </>);
}
