import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
import { Alert, Card, Col, Row, Spin, Typography } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import mapboxgl from 'mapbox-gl';
import { WindRoseControl } from '../maps/controls/rose-wind';
import { useHistory } from "react-router";
export var MapType;
(function (MapType) {
    MapType["VOC_ALARM_STATUS"] = "alarm_status";
    MapType["TEMPERATURE"] = "temperature";
    MapType["HUMIDITY"] = "humidity";
    MapType["ATM_PRESSURE"] = "atm_pressure";
})(MapType || (MapType = {}));
function useStations(type) {
    const { get } = useContext(AuthContext);
    const [querying, setQuerying] = useState(true);
    const [stations, setStations] = useState([]);
    async function getStations() {
        return await get(`/api/stats/map/type/${type}`);
    }
    useEffect(() => {
        ;
        (async () => {
            try {
                setQuerying(true);
                const response = await getStations();
                if (response.ok) {
                    const data = await response.json();
                    const stations = data.stations.map((station) => ({
                        ...station,
                    }));
                    setStations(stations);
                }
            }
            finally {
                setQuerying(false);
            }
        })();
    }, []);
    return {
        querying,
        stations,
    };
}
function Map({ stations, binary }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    const history = useHistory();
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            stations
                .filter(station => station.info.position)
                .forEach(station => {
                if (station.info.position.lat > north) {
                    north = station.info.position.lat;
                }
                if (station.info.position.lat < south) {
                    south = station.info.position.lat;
                }
                if (station.info.position.lng > east) {
                    east = station.info.position.lng;
                }
                if (station.info.position.lng < west) {
                    west = station.info.position.lng;
                }
            });
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 14,
            });
            map.current.on('load', () => {
                const features = stations.map(station => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [station.info.position.lng, station.info.position.lat],
                    }
                }));
                map.current.addSource(`stations`, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features,
                    },
                });
                features.forEach((feature, i) => {
                    const el = document.createElement('div');
                    el.className = `station station--${stations[i].info.type}`;
                    el.addEventListener('click', () => {
                        history.push(`/stations/${stations[i].info.id}`);
                    });
                    let html = '<b>' + stations[i].last.value + stations[i].last.units + '</b><br />' + stations[i].info.name;
                    let popup = new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false
                    }).setHTML(html);
                    let marker = new mapboxgl.Marker(el)
                        .setLngLat(feature.geometry.coordinates)
                        .setPopup(popup)
                        .addTo(map.current);
                    el.addEventListener('mouseenter', () => {
                        marker.togglePopup();
                    });
                    el.addEventListener('mouseleave', () => {
                        marker.togglePopup();
                    });
                });
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                /*
                                if (!binary) {
                                    map.current!.addSource(`stations-green`, {
                                        type: 'geojson',
                                        data: {
                                            type: 'FeatureCollection',
                                            features: stations
                                                .filter(station => station.info.position)
                                                .filter(station => station.last.value !== null)
                                                .map(station => ({
                                                    type: 'Feature',
                                                    geometry: {
                                                        type: 'Point',
                                                        coordinates: [
                                                            station.info.position!.lng,
                                                            station.info.position!.lat,
                                                        ],
                                                    },
                                                    properties: {
                                                        title: station.last.value + ' ' + station.last.units,
                                                    },
                                                })),
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: 'points-green',
                                        type: 'symbol',
                                        source: 'stations-green',
                                        layout: {
                                            'text-field': ['get', 'title'],
                                            'text-offset': [0, 0.5],
                                            'text-size': 12,
                                            'text-anchor': 'top',
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: `stations-green`,
                                        type: 'circle',
                                        source: `stations-green`,
                                        paint: {
                                            'circle-color': '#008800',
                                            'circle-radius': 10,
                                            'circle-opacity': 0.85,
                                        },
                                    })
                                } else {
                                    map.current!.addSource(`stations-red`, {
                                        type: 'geojson',
                                        data: {
                                            type: 'FeatureCollection',
                                            features: stations
                                                .filter(station => station.info.position)
                                                .filter(station => station.last.value === 1)
                                                .map(station => ({
                                                    type: 'Feature',
                                                    geometry: {
                                                        type: 'Point',
                                                        coordinates: [
                                                            station.info.position!.lng,
                                                            station.info.position!.lat,
                                                        ],
                                                    },
                                                    properties: {
                                                        title: station.info.name,
                                                    },
                                                })),
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: 'points-red',
                                        type: 'symbol',
                                        source: 'stations-red',
                                        layout: {
                                            'text-field': ['get', 'title'],
                                            'text-offset': [0, 0.5],
                                            'text-size': 12,
                                            'text-anchor': 'top',
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: `stations-red`,
                                        type: 'circle',
                                        source: `stations-red`,
                                        paint: {
                                            'circle-color': '#ff0000',
                                            'circle-radius': 10,
                                            'circle-opacity': 0.85,
                                        },
                                    })

                                    map.current!.addSource(`stations-green`, {
                                        type: 'geojson',
                                        data: {
                                            type: 'FeatureCollection',
                                            features: stations
                                                .filter(station => station.info.position)
                                                .filter(station => station.last.value === 0)
                                                .map(station => ({
                                                    type: 'Feature',
                                                    geometry: {
                                                        type: 'Point',
                                                        coordinates: [
                                                            station.info.position!.lng,
                                                            station.info.position!.lat,
                                                        ],
                                                    },
                                                    properties: {
                                                        title: station.info.name,
                                                    },
                                                })),
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: 'points-green',
                                        type: 'symbol',
                                        source: 'stations-green',
                                        layout: {
                                            'text-field': ['get', 'title'],
                                            'text-offset': [0, 0.5],
                                            'text-size': 12,
                                            'text-anchor': 'top',
                                        },
                                    })

                                    map.current!.addLayer({
                                        id: `stations-green`,
                                        type: 'circle',
                                        source: `stations-green`,
                                        paint: {
                                            'circle-color': '#008800',
                                            'circle-radius': 10,
                                            'circle-opacity': 0.85,
                                        },
                                    })
                                }

                                map.current!.addSource(`stations-grey`, {
                                    type: 'geojson',
                                    data: {
                                        type: 'FeatureCollection',
                                        features: stations
                                            .filter(station => station.info.position)
                                            .filter(station => station.last.value === null)
                                            .map(station => ({
                                                type: 'Feature',
                                                geometry: {
                                                    type: 'Point',
                                                    coordinates: [
                                                        station.info.position!.lng,
                                                        station.info.position!.lat,
                                                    ],
                                                },
                                                properties: {
                                                    title: station.info.name,
                                                },
                                            })),
                                    },
                                })

                                map.current!.addLayer({
                                    id: 'points-grey',
                                    type: 'symbol',
                                    source: 'stations-grey',
                                    layout: {
                                        'text-field': ['get', 'title'],
                                        'text-offset': [0, 0.5],
                                        'text-size': 12,
                                        'text-anchor': 'top',
                                    },
                                })

                                map.current!.addLayer({
                                    id: `stations-grey`,
                                    type: 'circle',
                                    source: `stations-grey`,
                                    paint: {
                                        'circle-color': '#888888',
                                        'circle-radius': 10,
                                        'circle-opacity': 0.85,
                                    },
                                })*/
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                map.current.setCenter([west - (west - east) / 2, south + (north - south) / 2]);
                map.current.fitBounds([
                    [west, south],
                    [east, north],
                ], { padding: 50 });
            });
        }
    });
    return (<div ref={mapContainer} style={{
            height: 600,
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>);
}
function StationTypeFormatter(value) {
    switch (value) {
        case 'meteo':
            return 'Estación meteorológica';
        case 'bio':
            return 'Condiciones biomasa';
        case 'smoke':
            return 'Detección de humo';
        case 'ambiental':
            return 'Ambiental';
        case 'fwi':
            return 'Riesgo de incendio';
        case 'zeus':
            return 'Integración ZEUS API';
        default:
            return value;
    }
}
export default function MapStations({ type, binary, }) {
    const { stations, querying } = useStations(type);
    return querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
                    Se están cargando los datos de las estaciones.
                    <Spin indicator={<Loading3QuartersOutlined />}/>
                </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
            <Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
                <Col span={24} className="gDashboardZone" id="sideinfoMap">
                    <Card>
                        {stations.length ? <Map stations={stations} binary={binary}/> :
            <Alert message={"Sección no disponible para esta instalación."} type={"warning"}/>}

                        <div className="map-legend">
                            {stations.reduce((acc, station) => {
            if (acc.find(s => (station.info.type === s.info.type)) === undefined) {
                acc.push(station);
            }
            return acc;
        }, []).map(station => (<div className="map-legend--item">
                                    <div className={`map-legend--item-dot station--${station.info.type}`}></div>
                                    <div className="map-legend--item-text">{StationTypeFormatter(station.info.type)}</div>

                                </div>))}
                        </div>
                    </Card>
                </Col>
            </Row>
        </>);
}
