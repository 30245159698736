import { useContext, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Alert, Typography, Row, Col, Spin, Card } from 'antd';
import { WindRoseControl } from '../maps/controls/rose-wind';
import { useParams } from 'react-router';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { AuthContext } from '../contexts/Auth';
function SimulationsMap({ className, simulation, }) {
    const map = useRef(null);
    const mapContainer = useRef(null);
    useEffect(() => {
        if (!map.current && mapContainer.current) {
            let north = -90, south = 90, east = -180, west = 180;
            if (simulation.geojson) {
                simulation.geojson.features.forEach((feature) => {
                    feature.geometry.coordinates.map(points => {
                        points.forEach(point => {
                            north = point[1] > north ? point[1] : north;
                            south = point[1] < south ? point[1] : south;
                            west = point[0] < west ? point[0] : west;
                            east = point[0] > east ? point[0] : east;
                        });
                    });
                });
            }
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: simulation.lat
                    ? [+simulation.lng, +simulation.lat]
                    : [west + (east - west) / 2, north + (south - north) / 2],
                zoom: 9,
                interactive: true,
            });
            map.current.on('load', () => {
                map.current.addControl(new WindRoseControl('gWindRoseControl'), 'top-right');
                if (simulation.geojson) {
                    map.current.addSource(`simulation-${simulation.id}`, {
                        type: 'geojson',
                        data: simulation.geojson,
                    });
                    map.current.addLayer({
                        id: `simulation-layer-${simulation.id}`,
                        source: `simulation-${simulation.id}`,
                        type: 'fill',
                        paint: {
                            'fill-color': 'red',
                            'fill-opacity': 1 / simulation.geojson.features.length,
                        },
                    });
                    map.current.addLayer({
                        id: `simulation-outline-${simulation.id}`,
                        type: 'line',
                        source: `simulation-${simulation.id}`,
                        layout: {},
                        paint: {
                            'line-color': 'red',
                            'line-width': 3,
                        },
                    });
                }
                const el = document.createElement('div');
                el.className = `simulation simulation--${simulation.code === '200'
                    ? 'finished'
                    : simulation.code === '0'
                        ? 'pending'
                        : 'failed'}`;
                new mapboxgl.Marker(el)
                    .setLngLat([+simulation.lng, +simulation.lat])
                    .addTo(map.current);
                map.current.dragRotate.disable();
                map.current.touchZoomRotate.disableRotation();
                if (simulation.geojson) {
                    map.current.setCenter([
                        west - (west - east) / 2,
                        south + (north - south) / 2,
                    ]);
                    map.current.fitBounds([
                        [west, south],
                        [east, north],
                    ], { padding: 50 });
                }
                else {
                    map.current.panTo([+simulation.lng, +simulation.lat], {
                        duration: 2000,
                    });
                }
            });
        }
    }, [map]);
    return (<div id="map-container">
      <div id="map" ref={mapContainer} className={className} style={{
            height: '60vh',
            width: 'calc(100%)',
            margin: '1rem 0',
            boxSizing: 'content-box',
        }}/>
    </div>);
}
export default function FireSimulationDetails() {
    const { get, chosenProject } = useContext(AuthContext);
    const { id } = useParams();
    const [simulation, setSimulation] = useState();
    const [querying, setQuerying] = useState(true);
    async function getData() {
        const response = await get(`/api/fire-simulations/${chosenProject}/${id}`);
        const json = (await response.json());
        return {
            ...json,
            geojson: json.geojson ? JSON.parse(json.geojson) : null,
        };
    }
    useEffect(() => {
        ;
        (async () => {
            setQuerying(true);
            const _simulation = await getData();
            setSimulation(_simulation);
            setQuerying(false);
        })();
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            ;
            (async () => {
                setSimulation(await getData());
            })();
        }, 10 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (<>
      {querying ? (<Alert message="Cargando datos..." description={<Typography.Text>
              Se están cargando los datos de las zonas.{' '}
              <Spin indicator={<Loading3QuartersOutlined />}/>
            </Typography.Text>} type="info" showIcon style={{ margin: '1rem' }}/>) : (<>
          <Row align="top">
            <Typography.Title className="gTitle">
              Simulación "{simulation?.simulationId}" durante{' '}
              {simulation?.duration} horas
            </Typography.Title>
          </Row>
          <Row justify="space-around" align="middle" className="gDashboardZones" id="sideinfo">
            <Col span={24} className="gDashboardZone" id="sideinfoMap">
              <Card>
                <SimulationsMap className="simulation-details-map" simulation={simulation}/>
                <div className="map-legend">
                  <div className="map-legend--item">
                    <div className="map-legend--item-dot simulation--failed"></div>
                    <div className="map-legend--item-text">Error</div>
                  </div>
                  <div className="map-legend--item">
                    <div className="map-legend--item-dot simulation--pending"></div>
                    <div className="map-legend--item-text">En curso</div>
                  </div>
                  <div className="map-legend--item">
                    <div className="map-legend--item-dot simulation--finished"></div>
                    <div className="map-legend--item-text">Finalizada</div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: '1rem', width: '100%' }}>
              {simulation?.code === '0' ? (<Alert message="Atención" description="Esta simulación aún está en curso" type="warning" showIcon style={{ padding: '1rem' }}/>) : simulation?.code !== '200' ? (<Alert message="Atención" description="Esta simulación ha fallado" type="error" showIcon style={{ padding: '1rem' }}/>) : null}
            </Col>
          </Row>
        </>)}
    </>);
}
