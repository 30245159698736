import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
function ProjectName({ id }) {
    const { get } = useContext(AuthContext);
    const [name, setName] = useState('...');
    useEffect(() => {
        ;
        (async () => {
            if (id !== undefined) {
                const response = await get(`/api/project/${id}`);
                const { name } = await response.json();
                setName(name);
            }
        })();
    }, []);
    return <>{name}</>;
}
export default [
    {
        key: 'email',
        label: 'Email',
        required: true,
    },
    {
        key: 'name',
        label: 'Nombre',
        required: true,
    },
    {
        key: 'password',
        label: 'Contraseña',
        required: true,
        optionalForUpdate: true,
        type: 'password',
        hideOnList: true,
    },
    {
        key: 'project_id',
        label: 'Proyecto',
        required: true,
        type: 'options',
        optionsMapper: {
            url: '/api/project',
            mapper: (options) => (options || []).map(({ id: value, name: label }) => ({
                value,
                label,
            })),
        },
        render: (projectId) => <ProjectName id={projectId}/>,
    },
    {
        key: 'projects',
        label: 'Seleccionables',
        required: false,
        type: 'options',
        multiple: true,
        render: (options) => (<>
        {options.map(id => (<>
            <ProjectName id={id.toString()}/>
            &nbsp;
          </>))}
      </>),
        optionsMapper: {
            url: '/api/project',
            mapper: (options, values) => (options || [])
                .filter(option => option.id !== values.project_id)
                .map(({ id: value, name: label }) => ({
                value,
                label,
            })),
        },
    },
    {
        key: 'roles',
        label: 'Roles',
        type: 'options',
        multiple: true,
        render: (options) => <>{options.join(', ')}</>,
        optionsMapper: {
            url: '/api/user/roles',
            mapper: (options) => (options || []).map(({ id: value, name: label }) => ({
                value,
                label,
            })),
        },
    },
];
