import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLoadCollection from '../hooks/useObjectsCollection';
import { Col, Row, Table, Tag } from 'antd';
import { JSON_HEADERS } from '../hooks/useItemInfo';
import { AuthContext } from '../contexts/Auth';
function useUser(id) {
    const [querying, setQuerying] = useState(false);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState({});
    const { tokenInfo } = useContext(AuthContext);
    async function getUser() {
        if (!users[id]) {
            try {
                setQuerying(true);
                const response = await fetch(`/api/user/${id}`, {
                    headers: {
                        ...JSON_HEADERS,
                        Authorization: `${tokenInfo.tokenType} ${tokenInfo.token}`,
                    },
                });
                if (response.ok) {
                    const _user = await response.json();
                    setUser({ ..._user, id: _user.id.toString() });
                    setUsers(users => ({ ...users, [_user.id]: _user }));
                }
            }
            finally {
                setQuerying(false);
            }
        }
        else {
            setUser(users[id]);
        }
    }
    useEffect(() => {
        getUser().then();
    }, []);
    return { user };
}
function UserInfo({ id }) {
    const { user } = useUser(id);
    return <>{user ? user.name : '...'}</>;
}
export default function () {
    const { collection, querying, reloadCollection } = useLoadCollection('device-action');
    const history = useHistory();
    const [showCreationDialog, setShowCreationDialog] = useState(false);
    const [actionIdToDelete, setActionIdToDelete] = useState(null);
    const [actionIdToUpdate, setActionIdToUpdate] = useState(null);
    function handleCreationDialogShow() {
        setShowCreationDialog(true);
    }
    function handleCreationDialogHide() {
        setShowCreationDialog(false);
    }
    function handleDeleteDialogShow(id) {
        return () => {
            setActionIdToDelete(id);
        };
    }
    function handleDeleteDialogHide() {
        setActionIdToDelete(null);
    }
    function handleUpdateDialogShow(id) {
        return () => {
            setActionIdToUpdate(id);
        };
    }
    function handleUpdateDialogHide() {
        setActionIdToUpdate(null);
    }
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Personalizado',
            dataIndex: 'custom',
            key: 'custom',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Condiciones',
            dataIndex: 'conditions',
            key: 'conditions',
            render: (conditions) => (<>
          {conditions.map(({ operator, value }, i) => (<Tag key={i}>
              {operator} {value}
            </Tag>))}
        </>),
        },
        {
            title: 'Notificaciones',
            dataIndex: 'notifications',
            key: 'notifications',
            render: (notifications) => (<>
          {notifications.map(({ user_id, channel }, i) => (<Tag key={i}>
                {channel} <UserInfo id={user_id}/>
              </Tag>))}
        </>),
        },
    ];
    return (<Row justify="center" align="middle">
      <Col>
        <Table dataSource={collection} loading={querying} columns={columns}/>
      </Col>
    </Row>);
}
