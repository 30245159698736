import * as React from 'react';
import { useContext, useState } from 'react';
import { Modal, Radio, Space } from 'antd';
import { AuthContext } from '../contexts/Auth';
import { useHistory } from 'react-router';
export default function ProjectChooserMenu({ open, onOk, onCancel, }) {
    const history = useHistory();
    const { user, projects, chosenProject, chooseProject, me } = useContext(AuthContext);
    const [tempChosenProject, setTempChosenProject] = useState();
    function handleOptionChange({ target: { value } }) {
        setTempChosenProject(value);
    }
    function goToMainPage() {
        history.push('/');
    }
    return (<Modal open={!!(open || (user && !chosenProject))} okText="Aceptar" onOk={() => {
            if (tempChosenProject !== undefined) {
                (async () => {
                    await chooseProject(tempChosenProject);
                    await me();
                    goToMainPage();
                    if (onOk) {
                        onOk();
                    }
                })();
            }
        }} onCancel={() => {
            if (onCancel) {
                onCancel();
            }
        }} okButtonProps={{ disabled: tempChosenProject === undefined }} maskClosable={false} closable centered cancelButtonProps={{ style: { display: 'none' } }} title="Selecciona el proyecto con el que quieres trabajar">
            <>
                <Radio.Group onChange={handleOptionChange}>
                    <Space direction="vertical">
                        {projects.map(({ id, name }) => (<Radio value={id} key={id}>
                                {name}
                            </Radio>))}
                    </Space>
                </Radio.Group>
            </>
        </Modal>);
}
