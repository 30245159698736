export default [
    {
        key: 'name',
        label: 'Nombre',
        required: true,
    },
    {
        key: 'description',
        label: 'Descripción',
        multiline: true,
    },
    {
        key: 'serial',
        label: 'Nº de serie',
        required: true,
    },
    {
        key: 'hash',
        label: 'Hash',
    },
    {
        key: 'format',
        label: 'Formato',
        required: true,
    },
    {
        key: 'position',
        label: 'Posición',
        type: 'json',
        validation: (value) => {
            if (value === '')
                return true;
            try {
                JSON.parse(value);
                return true;
            }
            catch (e) {
                return false;
            }
        },
        placeholder: 'Ejemplo: {"lat":39.522176,"lng":-0.518477}',
    },
    {
        key: 'order_position',
        label: 'Orden',
        type: 'number',
    },
    {
        key: 'type',
        type: 'staticOptions',
        label: 'Tipo',
        options: [
            { value: 'ambiental', label: 'Ambiental' },
            { value: 'bio', label: 'Bio' },
            { value: 'meteo', label: 'Meteo' },
            { value: 'fwi', label: 'FWI' },
            { value: 'pi', label: 'PI' },
            { value: 'smoke', label: 'Humo' },
        ],
    },
    {
        key: 'fwi',
        label: 'FWI',
        type: 'staticOptions',
        options: [
            { label: 'Si', value: true },
            { label: 'No', value: false },
        ],
    },
    {
        key: 'pi',
        label: 'PI',
        type: 'staticOptions',
        options: [
            { label: 'Si', value: true },
            { label: 'No', value: false },
        ],
    },
];
