import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
export default function useSideinfo() {
    const [installations, setInstallations] = useState([]);
    const [loading, setLoading] = useState(true);
    const { post } = useContext(AuthContext);
    const [reload, setReload] = useState(0);
    async function getSideinfo() {
        const response = await post('/api/stats/sideinfo');
        const installations = await response.json();
        setInstallations(installations);
        setReload(reload + 1);
    }
    useEffect(() => {
        ;
        (async () => {
            try {
                setLoading(true);
                await getSideinfo();
            }
            finally {
                setLoading(false);
            }
        })();
    }, []);
    function performAction(action) {
        ;
        (async () => {
            await post(`/api/stats/sideinfo/${action}`);
            await getSideinfo();
        })();
    }
    return { installations, performAction, loading, getSideinfo, reload };
}
