import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/Auth';
export const JSON_HEADERS = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
};
export default function useItemInfo(type, id, parentData) {
    const { tokenInfo } = useContext(AuthContext);
    const [item, setItem] = useState(null);
    const [querying, setQuerying] = useState(false);
    async function load() {
        if (id) {
            try {
                setQuerying(true);
                const response = await fetch(`/api/${type}/${id}`, {
                    headers: {
                        ...JSON_HEADERS,
                        Authorization: `${tokenInfo.tokenType} ${tokenInfo.token}`,
                    },
                });
                const json = await response.json();
                setItem({ ...json, id: json.id.toString() });
            }
            finally {
                setQuerying(false);
            }
        }
    }
    async function deleteItem() {
        try {
            setQuerying(true);
            return fetch(`/api/${type}/${id}`, {
                method: 'DELETE',
                headers: {
                    ...JSON_HEADERS,
                    Authorization: `${tokenInfo.tokenType} ${tokenInfo.token}`,
                },
            });
        }
        catch (e) {
            throw new Error('UNAUTHORIZED');
        }
        finally {
            setQuerying(true);
        }
    }
    async function create(values) {
        try {
            setQuerying(true);
            return await fetch(`/api/${type}`, {
                method: 'POST',
                headers: {
                    ...JSON_HEADERS,
                    Authorization: `${tokenInfo.tokenType} ${tokenInfo.token}`,
                },
                body: JSON.stringify(parentData ? { ...values, [parentData.key]: item.parent_id } : values),
            });
        }
        catch {
            throw new Error('UNAUTHORIZED');
        }
        finally {
            setQuerying(false);
        }
    }
    async function update(values) {
        try {
            setQuerying(true);
            return await fetch(`/api/${type}/${id}`, {
                method: 'PUT',
                headers: {
                    ...JSON_HEADERS,
                    Authorization: `${tokenInfo.tokenType} ${tokenInfo.token}`,
                },
                body: JSON.stringify(values),
            });
        }
        finally {
            setQuerying(false);
        }
    }
    useEffect(() => {
        if (id) {
            load().then();
        }
    }, [type, id]);
    return {
        querying,
        item,
        update,
        deleteItem,
        create,
    };
}
