import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useLoadCollection from '../hooks/useObjectsCollection';
import { Button, Col, Row } from 'antd';
import Collection from '../components/Collection';
import fields from '../fields/projects';
import zoneFields from '../fields/zones';
import ItemDialog, { ItemAction } from '../components/ItemDialog';
import { PlusOutlined } from '@ant-design/icons';
export default function () {
    const { querying, collection, reloadCollection } = useLoadCollection('project');
    const [projectToUpdate, setProjectToUpdate] = React.useState(null);
    const [creationDialogOpen, setCreationDialogOpen] = React.useState(false);
    const [projectToDelete, setProjectToDelete] = React.useState(null);
    const [projectIdToAddZone, setProjectIdToAddZone] = React.useState(null);
    const history = useHistory();
    function onUpdateProjectModalCloseHandler() {
        setProjectToUpdate(null);
    }
    function onCreateProjectDialogCloseHandler() {
        setCreationDialogOpen(false);
    }
    function onCreateProjectDialogOpenHandler() {
        setCreationDialogOpen(true);
    }
    function onDeleteProjectDialogOpenHandler(id) {
        return () => {
            setProjectToDelete(id);
        };
    }
    function onDeleteProjectDialogCloseHandler() {
        setProjectToDelete(null);
    }
    function onUpdateProjectDialogOpenHandler(id) {
        return () => {
            setProjectToUpdate(id);
        };
    }
    function onCreateProjectZoneDialogOpenHandler(id) {
        return () => {
            setProjectIdToAddZone(id);
        };
    }
    function onCreateProjectZoneDialogCloseHandler() {
        setProjectIdToAddZone(null);
    }
    function goToZones(id) {
        return () => {
            history.push(`/projects/${id}/zones`);
        };
    }
    return (<>
      <Row justify="center" align="middle" style={{ minHeight: 280 }}>
        <Col>
          <ItemDialog type="project" id={null} visible={creationDialogOpen} fields={fields} action={ItemAction.Create} onAccept={() => {
            reloadCollection().then(onCreateProjectDialogCloseHandler);
        }} onCancel={onCreateProjectDialogCloseHandler} title={() => 'Crear un nuevo proyecto'}/>
          <ItemDialog type="zone" visible={!!projectIdToAddZone} id={null} fields={zoneFields} action={ItemAction.Create} onAccept={() => {
            history.push('/zones');
        }} onCancel={onCreateProjectZoneDialogCloseHandler} title={() => 'Crear una nueva zona en el proyecto'} parentData={{
            key: 'project_id',
            id: projectIdToAddZone,
        }}/>
          <ItemDialog type="project" id={projectToUpdate} fields={fields} action={ItemAction.Update} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
                onUpdateProjectModalCloseHandler();
            })();
        }} onCancel={onUpdateProjectModalCloseHandler} title={item => `Actualizar proyecto "${item?.name}"`}/>
          <ItemDialog type="project" id={projectToDelete} fields={fields} action={ItemAction.Delete} onAccept={() => {
            ;
            (async () => {
                await reloadCollection();
            })();
        }} onCancel={onDeleteProjectDialogCloseHandler} title={item => `¿Eliminar proyecto "${item?.name}"?`}/>
          <Collection querying={querying} goToChildren={goToZones} fields={fields} collection={collection.filter(({ id }) => id !== '0')} onCreateChildItemRequest={onCreateProjectZoneDialogOpenHandler} onItemUpdateRequest={onUpdateProjectDialogOpenHandler} onItemDeleteRequest={onDeleteProjectDialogOpenHandler}/>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button shape="circle" size="large" onClick={onCreateProjectDialogOpenHandler} style={{ right: 10, bottom: 10, position: 'absolute' }} icon={<PlusOutlined />}/>
        </Col>
      </Row>
    </>);
}
