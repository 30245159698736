import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/Auth';
export default function useLoadCollection(objectName) {
    const [collection, setCollection] = useState([]);
    const [querying, setQuerying] = useState(true);
    const { get, tokenInfo } = useContext(AuthContext);
    async function reloadCollection() {
        try {
            setQuerying(true);
            const response = await get(`/api/${objectName}`);
            const data = await response.json();
            setCollection((data || []).map((object) => ({
                ...object,
                id: object.id.toString(),
            })));
        }
        finally {
            setQuerying(false);
        }
    }
    useEffect(() => {
        ;
        (async () => {
            if (tokenInfo) {
                await reloadCollection();
            }
        })();
    }, [objectName, tokenInfo]);
    return {
        collection,
        querying,
        reloadCollection,
    };
}
