export default [
    {
        key: 'name',
        label: 'Nombre',
        required: true,
    },
    {
        key: 'slug',
        label: 'Slug',
        required: true,
    },
    {
        key: 'latitude',
        label: 'Latitud',
        required: true,
        type: 'number',
    },
    {
        key: 'description',
        label: 'Descripción',
        multiline: true,
        required: true,
    },
];
